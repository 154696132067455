import {addClass} from './classes';
import {findParent} from './dom';
import {replaceFormPrefix} from './forms';
import {unsavedChangesMessage} from "../widgets/site_edit";

// Return pre-filled init function
export default (
    containerId, newRowClassName, addNewButtonId, formPrefix,
    removeControlClassName = null, onSelectHandler = () => {},
) => () => {
    const tableBodyContainer = document.querySelector(`#${containerId} table tbody`);
    const lastNewAttachmentElement = document.querySelector(`.${newRowClassName}:last-child`);

    lastNewAttachmentElement.onchange = onSelectHandler;
    const newAttachmentElement = lastNewAttachmentElement.cloneNode(true);
    let initialTotal = null;

    const addButton = document.getElementById(addNewButtonId);
    addButton.onclick = (e) => {
        e.stopPropagation();
        const totalElement = document.getElementById(`id_${formPrefix}-TOTAL_FORMS`);
        const total = parseInt(totalElement.value, 10);
        if (!initialTotal) {
            initialTotal = total;
        }

        const newElement = newAttachmentElement.cloneNode(true);
        newElement.onchange = onSelectHandler;
        replaceFormPrefix(newElement, formPrefix, `-${initialTotal - 1}-`, `-${total}-`);

        tableBodyContainer.appendChild(newElement);
        totalElement.value = total + 1;
        window.onbeforeunload = () => unsavedChangesMessage;
    };

    if (removeControlClassName) {
        const removeClickHandler = (e) => {
            const input = e.target;
            addClass(findParent(input, elem => elem.tagName === 'TR'), 'hide');
            input.checked = true;
        };
        for (const input of document.querySelectorAll(`.${removeControlClassName} input`)) {
            input.onclick = removeClickHandler;
        }
    }
};
