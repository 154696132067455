function bindFunctionToLinks(linkClass, handler, allowDefault) {
    const changeLinks = document.getElementsByClassName(linkClass);
    for (let i = changeLinks.length - 1; i >= 0; i--) {
        const link = changeLinks[i];
        link.onclick = (e) => {
            if (!allowDefault) {
                e.preventDefault();
            }
            handler(link, e);
        };
    }
}

function disableInputOnClick(className) {
    const original = window.onbeforeunload;
    window.onbeforeunload = () => {
        const elements = document.getElementsByClassName(className);
        for (let i = elements.length - 1; i >= 0; i--) {
            const element = elements[i];
            element.disabled = true;
        }
        if (original) {
            return original();
        }

        return null;
    };
}

export {bindFunctionToLinks, disableInputOnClick};
