import {addClass, removeClass} from '../../utils/classes';
import {gettext} from "../../utils/text";
import {unsavedChangesMessage} from "./index";


export default () => {
    const userEmploymentCache = {};

    const applyOnEmployeeTable = (fields, operation = () => {}) => {
        for (const field of fields) {
            operation(field);
        }
    };

    const partnerToggle = parentToggle => {
        const checked = parentToggle.checked;
        const target = parentToggle.getAttribute('data-target');
        const table = document.getElementById(target);
        if (checked) {
            applyOnEmployeeTable(table.querySelectorAll('.js-user-employments-field'), field => {
                const id = field.getAttribute('id');
                const value = userEmploymentCache[id];
                if (value !== undefined) {
                    field.checked = value;
                }
            });
            removeClass(table, 'hide');
        } else {
            addClass(table, 'hide');
            applyOnEmployeeTable(table.querySelectorAll('.js-user-employments-field'), field => {
                const id = field.getAttribute('id');
                userEmploymentCache[id] = field.checked;
                field.checked = false;
            });
        }
    };

    const partners = document.querySelectorAll('.js-partner-checkbox');
    for (const partner of partners) {
        partner.onchange = evt => partnerToggle(evt.target);
        partnerToggle(partner);
    }

    const onEmployeeButtonClick = button => {
        const target = button.getAttribute('data-target');
        const action = button.getAttribute('data-action');
        const table = document.getElementById(target);
        const partner = document.querySelector(`.js-partner-checkbox[data-target=${target}]`);

        if (action == 'add') {
            applyOnEmployeeTable(table.querySelectorAll('.js-user-employments-field'), field => {
                const id = field.getAttribute('id');
                userEmploymentCache[id] = true;
            });
            partner.checked = true;
            partnerToggle(partner);
            window.onbeforeunload = () => unsavedChangesMessage;
        } else if (action === 'remove') {
            applyOnEmployeeTable(table.querySelectorAll('.js-user-employments-field'), field => {
                field.checked = false;
            });
            partner.checked = false;
            partnerToggle(partner);
            window.onbeforeunload = () => unsavedChangesMessage;
        }
    };

    const employeeButtons = document.querySelectorAll('.js-employees-button');
    for (const employeeButton of employeeButtons) {
        employeeButton.onclick = evt => onEmployeeButtonClick(evt.target);
    }

    const submitButton = document.querySelector('.js-confirm');
    const originalSubmitEvent = submitButton.onclick;
    submitButton.onclick = evt => {
        const checked = document.querySelectorAll('.js-user-employments-field[type=checkbox]:checked').length;
        if (!checked) {
            originalSubmitEvent(evt);
        }
    };
};
