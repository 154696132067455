import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'tg-modal';

import {truncfloatformat} from '../../utils/number';
import {gettext} from "../../utils/text";

const AssortmentResults = ({isMaster, assortments, selectedModalAssortmentId, selectModalAssortment}) => {
    let body;
    let totals;
    let headers;

    const getValue = value => parseFloat(value) || 0;

    let destinationAmounts = (<h4>{gettext("No destinations")}</h4>);
    const selectedAssortment = assortments.find(a => a.id === selectedModalAssortmentId);
    const modalOpen = selectedModalAssortmentId !== null && selectedAssortment !== undefined;
    if (selectedAssortment && selectedAssortment.destinations) {
        const destinationAmountRows = Object.keys(selectedAssortment.destinations).map((t) => {
            const val = selectedAssortment.destinations[t];
            return (
                <tr key={t}>
                    <td className="text-left">{val.name}</td>
                    <td>{getValue(val.total_shipped)}</td>
                    <td>{getValue(val.total_confirmed)}</td>
                </tr>
            );
        });
        destinationAmounts = (
            <table className="table table-horizontal table-shadow table-assortments table-striped">
                <thead>
                    <tr>
                        <th className="text-left">{gettext("Destination")}</th>
                        <th>{gettext("Shipped")}</th>
                        <th>{gettext("Confirmed")}</th>
                    </tr>
                </thead>
                <tbody>
                    {destinationAmountRows}
                </tbody>
                <tfoot>
                    <tr>
                        <td className="text-left">{gettext("Total")}</td>
                        <td>{getValue(selectedAssortment.total_shipped)}</td>
                        <td>{getValue(selectedAssortment.total_confirmed)}</td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    if (isMaster) {
        headers = [gettext("Name"), gettext("Shipped"), gettext("Confirmed")]
        totals = {total_shipped: 0, total_confirmed: 0};
        body = assortments.map((item) => {
            totals.total_confirmed += getValue(item.total_confirmed);
            totals.total_shipped += getValue(item.total_shipped);
            return (
                <tr key={item.id}>
                    <td className="text-left">
                        <div className="clickable" onClick={() => selectModalAssortment(item.id)}>{item.name}</div>
                    </td>
                    <td>{truncfloatformat(getValue(item.total_shipped))}</td>
                    <td>{truncfloatformat(getValue(item.total_confirmed))}</td>
                </tr>
            );
        });
        totals = [
            <td key="total_shipped">{truncfloatformat(totals.total_shipped)}</td>,
            <td key="total_confirmed">{truncfloatformat(totals.total_confirmed)}</td>,
        ];
    } else {
        headers = [gettext("Name"), gettext("Harvested"), gettext("Forwarded"), gettext("Shipped"), gettext("Confirmed")];
        totals = {total_chopped: 0, total_transported: 0, total_shipped: 0, total_confirmed: 0};
        body = assortments.map((item) => {
            totals.total_chopped += getValue(item.total_chopped);
            totals.total_transported += getValue(item.total_transported);
            totals.total_shipped += getValue(item.total_shipped);
            totals.total_confirmed += getValue(item.total_confirmed);
            return (
                <tr key={item.id}>
                    <td className="text-left">
                        <div className="clickable" onClick={() => selectModalAssortment(item.id)}>{item.name}</div>
                    </td>
                    <td>{truncfloatformat(getValue(item.total_chopped))}</td>
                    <td>{truncfloatformat(getValue(item.total_transported))}</td>
                    <td>{truncfloatformat(getValue(item.total_shipped))}</td>
                    <td>{truncfloatformat(getValue(item.total_confirmed))}</td>
                </tr>
            );
        });
        totals = [
            <td key="total_chopped">{truncfloatformat(totals.total_chopped)}</td>,
            <td key="total_transported">{truncfloatformat(totals.total_transported)}</td>,
            <td key="total_shipped">{truncfloatformat(totals.total_shipped)}</td>,
            <td key="total_confirmed">{truncfloatformat(totals.total_confirmed)}</td>,
        ];
    }

    return (
        <span>
            <table className="table table-horizontal table-horizontal--light">
                <thead>
                    <tr>
                        {headers.map((item, idx) => <th key={idx} className={idx === 0 ? 'text-left' : ''}>{item}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {body}
                </tbody>
                <tfoot>
                    <tr key="total">
                        <td className="text-left">{gettext("TOTAL")}</td>
                        {totals}
                    </tr>
                </tfoot>
            </table>
            <Modal
                isOpen={modalOpen}
                title={selectedAssortment ? selectedAssortment.name : null}
                onCancel={() => selectModalAssortment(null)}
            >
                <Modal.Body>
                    {destinationAmounts}
                </Modal.Body>
            </Modal>
        </span>
    );
};


AssortmentResults.propTypes = {
    isMaster: PropTypes.bool.isRequired,
    assortments: PropTypes.array,
    selectedModalAssortmentId: PropTypes.number,
    selectModalAssortment: PropTypes.func,
};

export default AssortmentResults;
