


function findParent(element, validationFunc) {
    while (element.parentNode) {
        element = element.parentNode;
        if (validationFunc(element)) {
            return element;
        }
    }

    return null;
}

export {findParent};
