import React from 'react';
import {createStore} from 'redux';
import {connect, Provider} from 'react-redux';
import Select2 from 'react-select2-wrapper';

import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import {gettext} from "../../utils/text";

/* Keep this consistent with ELVIS and with storage.models:DestinationAdditionalProperty */
const elvisAdditionalPropertyTypes = [
    {id: 25001, title: gettext("Ship")},
    {id: 25002, title: gettext("Wagon")},
    {id: 25003, title: gettext("Freight contract")},
    {id: 25004, title: gettext("Supply contract")},
    {id: 25005, title: gettext("Charger")},
    {id: 25006, title: gettext("Acceptance")},
    {id: 25007, title: gettext("Landowner")},
    {id: 25008, title: gettext("Other")},
]

const elvisAdditionalPropertyTypesMap = {}
elvisAdditionalPropertyTypes.forEach(t => {elvisAdditionalPropertyTypesMap[t.id] = t})

const elvisAdditionalPropertyTypeChoices = elvisAdditionalPropertyTypes.map(propertyType => (
    {id: propertyType.id, text: propertyType.title}
))

const AdditionalPropertyTypeSelect = ({onChange, onAdd, value}) => (
    <div className="input-item">
        <div className="form-group label-floating">
            <label className="control-label">{gettext("Add new info")}</label>
            <div className="controls">
                <div className="row">
                    <div className="col-xs-9 form-group">
                        <Select2
                            multiple={false}
                            onSelect={onChange}
                            value={value}
                            options={{
                                theme: 'bootstrap',
                                width: '100%',
                            }}
                            data={elvisAdditionalPropertyTypeChoices}
                        />
                    </div>
                    <div className="col-xs-3">
                        <button
                            type="button"
                            className="btn btn-default btn-primary btn-raised btn-block mt-4"
                            disabled={!value}
                            onClick={onAdd}
                        >
                            {gettext("Add")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
AdditionalPropertyTypeSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    value: PropTypes.number,
}
AdditionalPropertyTypeSelect.defaultProps = {
    value: undefined,
}


class DestinationAdditionalProperties extends React.Component {
    /* This component renders an editable list of additional properties, and a widget to select type of new property
    * and add it.
    *
    * While destination form is managed by jQuery, so this component comes in self-contained way with help of
    * renderConnectedDestinationAdditionalProperties helper (see below). The helper provides function to set
    * values, which is required for re-initializing the form values from non-react code.
    *
    * Whole data flow of this form is following:
    *   1. Form is rendered with HTML template from DestinationForm
    *   2. DestinationAdditionalProperties is rendered on top of additional properties field (which is char field,
    *      containing JSON data) and tries to read data from value of the said field
    *   3. Non-react code sometimes re-initializes the form, settings input values from DJ_CONST.destinations[n],
    *      at this point for additional_properties fields setter function returned by the helper is used
    *      to update the value for this React controlled field as well
    *
    * Redux state is used internally for sake of simplicity to keep track of the value.
    * */
    static propTypes = {
        values: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            property_type: PropTypes.number.isRequired,
            id: PropTypes.number,
        })).isRequired,
        setValues: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            propertyType: undefined,
        };

        this.onAddValue = this.onAddValue.bind(this);
        this.onChangePropertyType = this.onChangePropertyType.bind(this);
        this.onRemoveValue = this.onRemoveValue.bind(this);
    }

    onRemoveValue(index) {
        const { setValues, values } = this.props;
        setValues(values.filter((item, idx) => (idx !== index)))
    }

    onAddValue() {
        const { propertyType } = this.state;
        const { setValues, values } = this.props;
        setValues([...values, {property_type: propertyType, value: ""}])
        this.setState({propertyType: undefined})
    }

    onChangeValue(index, event) {
        const { setValues, values } = this.props;
        setValues(values.map((item, idx) => (idx !== index ? item : {...item, value: event.target.value})))
    }

    onChangePropertyType(event) {
        this.setState({propertyType: parseInt(event.target.value)})
    }

    render() {
        const {values} = this.props;
        const {propertyType} = this.state;

        return (<div>
            <input type="hidden" value={JSON.stringify(values)} name="additional_properties" />
            {values.map((value, index) => <div key={`${value.property_type}-${index}`} className="tooltip-wrapper">
                <div className="form-group label-floating">
                    <label
                        className="control-label"
                        htmlFor={`additional-property-input-${value.property_type}-${index}`}
                    >
                        {elvisAdditionalPropertyTypesMap[value.property_type].title || `${value.property_type}`}
                    </label>
                    <input
                        onChange={
                            (event) => this.onChangeValue(index, event)
                        }
                        className="textInput form-control"
                        id={`additional-property-input-${value.property_type}-${index}`}
                        defaultValue={`${value.value}`}
                    />
                </div>
                <span className="tooltip tooltip--triangle tooltip--danger" data-tooltip={gettext("Remove")}>
                    <a onClick={() => this.onRemoveValue(index)}>
                        <i className="icon icon-remove icon-sized" />
                    </a>
                </span>
            </div>)}
            <AdditionalPropertyTypeSelect
                onChange={this.onChangePropertyType}
                onAdd={this.onAddValue}
                value={propertyType}
            />
        </div>)
    }
}


export const ACTION_SET_DESTINATION_ADDITIONAL_PROPERTIES_VALUES =
    'ACTION_SET_DESTINATION_ADDITIONAL_PROPERTIES_VALUES';


const mapStateToProps = (state) => ({
    values: state.values,
});

const mapDispatchToProps = (dispatch) => ({
    setValues: (values) => {
        dispatch({type: ACTION_SET_DESTINATION_ADDITIONAL_PROPERTIES_VALUES, values})
    },
});

export const ConnectedDestinationAdditionalProperties = connect(
    mapStateToProps, mapDispatchToProps
)(DestinationAdditionalProperties);


export const renderConnectedDestinationAdditionalProperties = (initialValues = null) => {
    /* Self-containment helper for DestinationAdditionalProperties component, see documentation on
    * DestinationAdditionalProperties. */

    // This is rendered by crispy_full_field, see destination_modal.html
    const additionalPropertiesFullField = document.getElementById('div_id_additional_properties');
    const additionalPropertiesField = document.getElementById('id_additional_properties');

    const getDestinationAdditionalPropertiesValue = (initialValues = null) => {
        /* Sets additional properties field value to given value, or, if it is missing, tries to read the value
        * from the actual form field, falling back to empty list of additional properties if all that fails.*/
        if (initialValues === null) {
            let parsedInitialValues = []
            if (additionalPropertiesField) {
                try {
                    parsedInitialValues = JSON.parse(additionalPropertiesField.value) || []
                } catch {}
            }

            return parsedInitialValues
        }

        return initialValues
    }

    const store = createStore((state, action) => {
        switch (action.type) {
        case ACTION_SET_DESTINATION_ADDITIONAL_PROPERTIES_VALUES:
            return {...state, values: action.values};
        default:
            return state;
        }
    }, {
        values: getDestinationAdditionalPropertiesValue(initialValues),
    });

    if (additionalPropertiesField && additionalPropertiesFullField) {
        ReactDOM.render(
            <Provider store={store}>
                <ConnectedDestinationAdditionalProperties />
            </Provider>,
            additionalPropertiesFullField,
        );
    }

    const setDestinationAdditionalPropertiesValues = (values) => {
        store.dispatch({
            type: ACTION_SET_DESTINATION_ADDITIONAL_PROPERTIES_VALUES,
            values: getDestinationAdditionalPropertiesValue(values)
        })
    }

    return {setDestinationAdditionalPropertiesValues}
}
