import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {getSiteFilter, getSitesData} from '../../selectors/queries';
import {filterSite} from '../../actions';
import SelectInput from './SelectInput';
import {gettext} from "../../utils/text";


const SiteSelect = ({value, values, onSelectChange}) => {
    const sites = values.map(item => ({id: item[0], text: item[1]}));
    return (
        <SelectInput
            label={gettext("Site")}
            values={sites}
            value={value}
            onSelectChange={onSelectChange}
        />
    );
};

SiteSelect.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    values: PropTypes.array,
    onSelectChange: PropTypes.func,
};

export default connect(
    state => ({
        value: getSiteFilter(state),
        values: getSitesData(state),
    }),
    dispatch => ({
        onSelectChange: value => dispatch(filterSite(value)),
    }),
)(SiteSelect);
