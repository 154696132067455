import React from 'react';
import PropTypes from 'prop-types';

import { gettext } from "utils/text";
import {VEPQuery} from "./shapes";
import VEPQueryImageModal from "./VEPQueryImageModal";


const CheckResultCell = ({value}) => (
    <td className={value ? "vep-result-restricted" : "vep-result-unrestricted"}>{value ? gettext("Yes") : gettext("No")}</td>
)

CheckResultCell.propTypes = {
    value: PropTypes.bool.isRequired,
}

const CheckResultNonIdealState = ({title, message, danger}) => (
    <React.Fragment>
        <tr>
            <th scope="row" colSpan={1 + CHECK_KEYS.length} className="vep-query-title">{title}</th>
        </tr>
        <tr>
            <td colSpan={1 + CHECK_KEYS.length} className={`text-center ${danger ? "text-danger" : "text-secondary"}`}>
                {message}
            </td>
        </tr>
    </React.Fragment>
)
CheckResultNonIdealState.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    danger: PropTypes.bool,
}
CheckResultNonIdealState.defaultProps = {
    danger: false,
}


const CHECK_KEYS = [
    "is_restricted_area",
    "is_key_habitat",
    "is_natura_area",
    "is_holy_place",
    "is_potential_key_habitat",
]

const VEPQueryResult = ({query}) => {
    const [isDetailOpen, setIsDetailOpen] = React.useState(false);
    const toggleDetailOpen = React.useCallback(() => {
        setIsDetailOpen(!isDetailOpen);
    }, [isDetailOpen])

    const {queryTitle, error, response, loading} = query;
    if (loading) {
        return <CheckResultNonIdealState title={queryTitle} message={gettext("Loading...")} />
    }

    if (error) {
        return <CheckResultNonIdealState title={queryTitle} message={`${error}`} danger />
    }

    const {result} = response;

    if (!result) {
        /* "Empty response from the server." */
        return <CheckResultNonIdealState title={queryTitle} message={gettext("Server did not respond with valid check data.")} danger />
    }

    return (
        <React.Fragment>
            <tr onClick={toggleDetailOpen}>
                <th scope="row" colSpan={1 + CHECK_KEYS.length} className="vep-query-title">
                    {queryTitle} - {result.check_type}
                </th>
            </tr>
            <tr onClick={toggleDetailOpen}>
                <th scope="row">
                    <VEPQueryImageModal
                        isOpen={isDetailOpen}
                        toggleOpen={toggleDetailOpen}
                        title={result.title}
                        imageUrl={result.image_url}
                    />
                    {result.title}
                </th>
                {CHECK_KEYS.map(
                    key => <CheckResultCell key={key} value={result[key]} />
                )}
            </tr>
            {(result.areas || []).map((area) => (
                <tr key={area.title} onClick={toggleDetailOpen}>
                    <th scope="row" style={{paddingLeft: "3em"}}>
                        {area.title}
                    </th>
                    {CHECK_KEYS.map(key => <CheckResultCell key={key} value={area[key]} />)}
                </tr>
            ))}
        </React.Fragment>
    )
}


VEPQueryResult.propTypes = {
    query: VEPQuery,
}

export default VEPQueryResult
