import React from 'react';
import Modal from 'tg-modal';


export default class VaheladuModal extends React.Component {

    header() {
        return (
            <div className="tg-modal-header">
                <button className="close" aria-hidden="true" onClick={this.props.onCancel}>
                    <img src={DJ_CONST.STATIC_URL + 'images/icon-close.svg'} />
                </button>
                <h4 className="tg-modal-title">
                    {this.props.title}
                </h4>
            </div>
        );
    }

    render() {
        return (
            <Modal {...this.props} title={this.header()}>
                {this.props.children}
            </Modal>
        );
    }
}
