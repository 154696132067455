import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import {confirmationClose} from '../actions';
import VaheladuModal from '../widgets/VaheladuModal';


class ConfirmationModal extends Component {
    static propTypes = {
        callback: PropTypes.func,
        closeModal: PropTypes.func,
        message: PropTypes.string,
        isOpen: PropTypes.bool,
        options: PropTypes.object,
    };

    confirm() {
        this.props.callback(true);
        this.props.closeModal();
    }

    cancel() {
        this.props.callback(false);
        this.props.closeModal();
    }

    render() {
        const options = {
            ok_btn_text: gettext('OK'),
            cancel_btn_text: gettext('Cancel'),
            ok_btn_classes: 'btn-raised btn-success',
            cancel_btn_classes: 'btn-raised btn-dark',
        };

        Object.assign(options, this.props.options || {});

        return (
            <VaheladuModal
                isOpen={this.props.isOpen}
                title={this.props.message}
                onCancel={() => this.cancel()}
                autoWrap
            >
                <button className={`btn ${options.ok_btn_classes}`} onClick={() => this.confirm()}>
                    {options.ok_btn_text}
                </button>
                <button className={`btn ${options.cancel_btn_classes}`} onClick={() => this.cancel()}>
                    {options.cancel_btn_text}
                </button>
            </VaheladuModal>
        );
    }
}

const mapStateToProps = (state) => ({
    isOpen: state.isModalOpen,
    message: state.message,
    options: state.options,
    callback: state.callback,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => {
        dispatch(confirmationClose());
    },
});

const ConfirmationModalConnector = connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);

export default ConfirmationModalConnector;
