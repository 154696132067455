import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {getDestinationFilter, getDestinationsData} from '../../selectors/queries';
import {filterDestination} from '../../actions';
import SelectInput from './SelectInput';
import {gettext} from "../../utils/text";

const DestinationSelect = ({value, values, onSelectChange}) => {
    const destinations = values.map(item => ({id: item[0], text: item[1]}));
    return <SelectInput label={gettext("Destination")} values={destinations} value={value} onSelectChange={onSelectChange} />;
};

DestinationSelect.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    values: PropTypes.array,
    onSelectChange: PropTypes.func,
};

export default connect(
    state => ({
        value: getDestinationFilter(state),
        values: getDestinationsData(state),
    }),
    dispatch => ({
        onSelectChange: value => dispatch(filterDestination(value)),
    }),
)(DestinationSelect);
