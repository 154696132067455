export function triggerNativeEvent(element, eventName, fixRequiredAttr) {
    // We do not want to mark the input to have an error by triggering a change event on an input with
    // required attribute before the user even has a chance to fill in the form
    const requiredValue = element.getAttribute('required');
    if (fixRequiredAttr && requiredValue !== null) {
        element.removeAttribute('required');
    }

    if (document.createEvent) {
        const event = document.createEvent('HTMLEvents');
        event.initEvent(eventName, true, false);
        element.dispatchEvent(event);
    } else {
        element.fireEvent(`on${eventName}`);
    }

    if (fixRequiredAttr && requiredValue !== null) {
        element.setAttribute('required', requiredValue);
    }
}
