import React from 'react'
import PropTypes from 'prop-types';

import {QRCodeSVG} from 'qrcode.react';
import Modal from 'tg-modal';
import {gettext} from "../utils/text";

/* Ref: https://thorgatedigital.atlassian.net/browse/VAH-245
*
* Some destinations implemented gates that only open to qr code containing EVR waybill number, the system for gates is
* operated by Almic https://almic.ee/
* */
export const AlmicWaybillQRCode = ({content, size, children}) => {
    const [isDetailOpen, setIsDetailOpen] = React.useState(false);
    const toggleDetailOpen = React.useCallback(() => {
        setIsDetailOpen(!isDetailOpen);
    }, [isDetailOpen])

    return (<div className="qr-code-conatiner">
        <a onClick={toggleDetailOpen} data-testid="qrcode-open-link">
            { children }
        </a>
        <Modal
            isOpen={isDetailOpen}
            onCancel={toggleDetailOpen}
            dialogClassName="tg-modal-dialog qr-code-modal-dialog"
            isBasic
        >
            <Modal.Body>
                <QRCodeSVG
                    value={content}
                    size={size}
                    onClick={toggleDetailOpen}
                    includeMargin
                    className="waybill-qr-code"
                />
            </Modal.Body>
            <div className="tg-modal-footer">
                <button
                    data-testid="qrcode-close-button"
                    type="button"
                    className="btn btn-secondary"
                    onClick={toggleDetailOpen}
                >
                    {gettext("Close")}
                </button>
            </div>
        </Modal>
    </div>);
}

AlmicWaybillQRCode.propTypes = {
    content: PropTypes.string.isRequired,
    size: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
};

AlmicWaybillQRCode.defaultProps = {
    size: 360,
};
