import {
    ACTION_OPEN_CONFIRMATION_MODAL,
    ACTION_CLOSE_CONFIRMATION_MODAL,
    ACTION_FETCH_ASSORTMENT_DETAIL,
    ACTION_ASSORTMENT_DETAIL_RESULTS,
    ACTION_CLOSE_ASSORTMENT_DETAIL,
    ACTION_OPEN_AMOUNT_CHANGES,
    ACTION_FETCHED_AMOUNT_CHANGES,
    ACTION_CLOSE_AMOUNT_CHANGES,
    ACTION_REFRESH_API_TOKEN,
    ACTION_API_TOKEN_REFRESHED,
    ACTION_OPEN_SEND_PUBLIC_LINK_MODAL,
    ACTION_CLOSE_SEND_PUBLIC_LINK_MODAL,
    ACTON_RECEIVE_PUBLIC_LINK,
    ACTION_SHOW_SEND_PUBLIC_LINK_SUCCESS,
    ACTION_HIDE_SEND_PUBLIC_LINK_MESSAGES,
    ACTION_SHOW_SEND_PUBLIC_LINK_ERROR,
    ACTION_QUERIES_IS_LOADING,
    ACTION_QUERIES_MODAL_ASSORTMENT,
    ACTION_QUERIES_FILTER_DATE,
    ACTION_QUERIES_FILTER_COMPANY,
    ACTION_QUERIES_FILTER_DESTINATION,
    ACTION_QUERIES_FILTER_SITE,
    ACTION_QUERIES_FILTER_EMPLOYEE,
    ACTION_QUERIES_FILTER_PARTNER,
    ACTION_QUERIES_FILTER_MANAGER,
    ACTION_QUERIES_RESULTS_ASSORTMENTS,
    ACTION_QUERIES_FOCUS_DATE_INPUT,
    ACTION_QUERIES_LOAD_COMPANIES,
    ACTION_QUERIES_LOAD_DESTINATIONS,
    ACTION_QUERIES_LOAD_EMPLOYEES,
    ACTION_QUERIES_LOAD_SITES,
    ACTION_QUERIES_LOAD_PARTNERS,
    ACTION_QUERIES_LOAD_MANAGERS,
    ACTION_SITE_EDIT_DESTINATIONS_SELECT,
    ACTION_SITE_EDIT_DESTINATIONS_REMOVE,
    ACTION_SITE_EDIT_SET_MY_DESTINATIONS,
    ACTION_SITE_EDIT_SET_MASTER_DESTINATIONS,
    ACTION_SITE_EDIT_DESTINATIONS_INFO_MODAL,
    ACTION_SITE_EDIT_SET_INACTIVE_DESTINATIONS,
    SET_VEP_CHECK_LOADING,
    SET_VEP_CHECK_ERROR,
    SET_VEP_CHECK_RESULT,
    ACTION_SITE_EDIT_UPDATE_DESTINATION_ADDITIONAL_DATA,
    ACTION_SITE_EDIT_REMOVE_DESTINATION_ADDITIONAL_DATA,
    ACTION_SITE_REVERT_DESTINATION_ADDITIONAL_DATA,
} from './actions';


export const confirmationReducer = (state = {isModalOpen: false, message: null, options: null, callback: null}, action) => {
    switch (action.type) {
        case ACTION_OPEN_CONFIRMATION_MODAL:
            return {
                isModalOpen: true,
                message: action.message,
                options: action.options,
                callback: action.callback,
            };
        case ACTION_CLOSE_CONFIRMATION_MODAL:
            return {
                isModalOpen: false,
                message: null,
                options: null,
                callback: null,
            };
        default:
            return state;
    }
};

const initialAssortmentState = {isModalOpen: false, isLoading: true, objects: null};

export const assortmentReducer = (state = initialAssortmentState, action) => {
    switch (action.type) {
        case ACTION_FETCH_ASSORTMENT_DETAIL:
            return {
                isModalOpen: true,
                isLoading: true,
                objects: null,
            };
        case ACTION_ASSORTMENT_DETAIL_RESULTS:
            const prevIsModalOpen = state.isModalOpen;
            return {
                isModalOpen: prevIsModalOpen,
                isLoading: false,
                objects: action.results,
            };
        case ACTION_CLOSE_ASSORTMENT_DETAIL:
            const prevObjects = state.objects;
            return {
                isModalOpen: false,
                isLoading: false,
                objects: prevObjects,
            };
        default:
            return state;
    }
};

const initialChangesState = {isModalOpen: false, isLoading: true, changes: null};

export const changesReducer = (state = initialChangesState, action) => {
    switch (action.type) {
        case ACTION_OPEN_AMOUNT_CHANGES:
            return {
                isModalOpen: true,
                isLoading: true,
                changes: null,
            };
        case ACTION_FETCHED_AMOUNT_CHANGES:
            const prevIsModalOpen = state.isModalOpen;
            return {
                isModalOpen: prevIsModalOpen,
                isLoading: false,
                changes: action.results,
            };
        case ACTION_CLOSE_AMOUNT_CHANGES:
            const prevChanges = state.changes;
            return {
                isModalOpen: false,
                isLoading: false,
                changes: prevChanges,
            };
        default:
            return state;
    }
};

export const settingsAPIReducer = (userToken) => {
    return (state = {apiToken: userToken, isLoading: false}, action) => {
        switch (action.type) {
            case ACTION_REFRESH_API_TOKEN:
                return {
                    apiToken: null,
                    isLoading: true,
                };
            case ACTION_API_TOKEN_REFRESHED:
                return {
                    apiToken: action.token,
                    isLoading: false,
                };
            default:
                return state;
        }
    }
};

const initialQueriesFilterState = {
    date: null, company: 0, destination: 0, site: 0, employee: 0, partner: 0, manager: 0,
};

export const queriesFiltersReducers = (state = initialQueriesFilterState, action) => {
    const value = parseInt(action.value, 10);

    switch (action.type) {
    case ACTION_QUERIES_FILTER_DATE:
        return {...state, date: {...action.value}};
    case ACTION_QUERIES_FILTER_COMPANY:
        return {...state, company: value};
    case ACTION_QUERIES_FILTER_DESTINATION:
        return {...state, destination: value};
    case ACTION_QUERIES_FILTER_SITE:
        return {...state, site: value};
    case ACTION_QUERIES_FILTER_EMPLOYEE:
        return {...state, employee: value};
    case ACTION_QUERIES_FILTER_PARTNER:
        return {...state, partner: value};
    case ACTION_QUERIES_FILTER_MANAGER:
        return {...state, manager: value};
    default:
        return state;
    }
};

const initialQueriesFilterDataState = {
    companies: [], destinations: [], sites: [], employees: {}, partners: [], managers: [],
};

export const queriesFilterDataReducers = (state = initialQueriesFilterDataState, action) => {
    switch (action.type) {
    case ACTION_QUERIES_LOAD_COMPANIES:
        return {...state, companies: action.companies};
    case ACTION_QUERIES_LOAD_DESTINATIONS:
        return {...state, destinations: action.destinations};
    case ACTION_QUERIES_LOAD_SITES:
        return {...state, sites: action.sites};
    case ACTION_QUERIES_LOAD_EMPLOYEES:
        return {...state, employees: action.employees};
    case ACTION_QUERIES_LOAD_PARTNERS:
        return {...state, partners: action.partners};
    case ACTION_QUERIES_LOAD_MANAGERS:
        return {...state, managers: action.managers};
    default:
        return state;
    }
};

export const queriesIsLoadingReducer = (state = false, action) => {
    switch (action.type) {
    case ACTION_QUERIES_IS_LOADING:
        return action.isLoading;
    default:
        return state;
    }
};

export const queriesModalAssortment = (state = null, action) => {
    switch (action.type) {
    case ACTION_QUERIES_MODAL_ASSORTMENT:
        return action.modalAssortment;
    default:
        return state;
    }
};

export const queriesFocusDateInput = (state = null, action) => {
    switch (action.type) {
    case ACTION_QUERIES_FOCUS_DATE_INPUT:
        return action.focusInput;
    default:
        return state;
    }
};

const initialQueriesData = {assortments: []};

export const queriesDataReducers = (state = initialQueriesData, action) => {
    switch (action.type) {
    case ACTION_QUERIES_RESULTS_ASSORTMENTS:
        return {
            ...state,
            assortments: action.assortments || [],
            cuttings: action.cuttings || [],
        };
    default:
        return state;
    }
};

export const siteInfoReducer = () => {
    return (state = {siteId: null, publicLink: null, linkType: null, isOpen: false, isLoading: false, hasError: false, hasSuccess: false}, action) => {
        switch (action.type) {
        case ACTION_OPEN_SEND_PUBLIC_LINK_MODAL:
            return {...state, isOpen: true};
        case ACTION_CLOSE_SEND_PUBLIC_LINK_MODAL:
            return {...state, isOpen: false, hasSuccess: false, hasError: false};
        case ACTON_RECEIVE_PUBLIC_LINK:
            return {...state, publicLink: action.publicLink, linkType: action.linkType, siteId: action.siteId};
        case ACTION_SHOW_SEND_PUBLIC_LINK_SUCCESS:
            return {...state, hasSuccess: true, hasError: false};
        case ACTION_HIDE_SEND_PUBLIC_LINK_MESSAGES:
            return {...state, hasSuccess: false, hasError: false};
        case ACTION_SHOW_SEND_PUBLIC_LINK_ERROR:
            return {...state, hasSuccess: false, hasError: true};
        default:
            return state;
        }
    };
};


export const selectedDestinationsReducer = (state = [], action) => {
    switch (action.type) {
    case ACTION_SITE_EDIT_DESTINATIONS_SELECT:
        return [...state, ...action.values];
    case ACTION_SITE_EDIT_DESTINATIONS_REMOVE:
        return [...state.filter(x => !action.values.includes(x))];
    default:
        return state;
    }
};

export const selectedDestinationReducer = (state = null, action) => {
    switch (action.type) {
    case ACTION_SITE_EDIT_DESTINATIONS_INFO_MODAL:
        return action.selectedDestination;
    default:
        return state;
    }
};

export const masterDestinationsReducer = (state = [], action) => {
    switch (action.type) {
    case ACTION_SITE_EDIT_SET_MASTER_DESTINATIONS:
        return [...action.destinations];
    default:
        return state;
    }
};

export const myDestinationsReducer = (state = [], action) => {
    switch (action.type) {
    case ACTION_SITE_EDIT_SET_MY_DESTINATIONS:
        return [...action.destinations];
    default:
        return state;
    }
};

export const inactiveDestinationReducer = (state = [], action) => {
    switch (action.type) {
    case ACTION_SITE_EDIT_SET_INACTIVE_DESTINATIONS:
        return [...action.destinations];
    default:
        return state;
    }
};

export const siteDestinationsDataReducer = (state = [], action) => {
    switch (action.type) {
        case ACTION_SITE_EDIT_UPDATE_DESTINATION_ADDITIONAL_DATA:
            const existingDestination = state.find(destination => destination.id === action.destinationId);

            if (existingDestination) {
                return state.map(destination =>
                    destination.id === action.destinationId
                        ? { ...destination, ...action.data }
                        : destination
                );
            } else {
                return [...state, { id: action.destinationId, ...action.data }];
            }
        case ACTION_SITE_EDIT_REMOVE_DESTINATION_ADDITIONAL_DATA:
            return state.filter(destination => destination.id !== action.destinationId);
        default:
            return state;
    }
}

// VEP check reducer
export const vepCheckReducer = (state = [], action) => {
    switch (action.type) {
        case SET_VEP_CHECK_LOADING: {
            const { queryId, queryTitle } = action

            return [{queryId, queryTitle, response: null, error: null, loading: true}, ...state]
        }
        case SET_VEP_CHECK_RESULT: {
            const { queryId, response } = action

            return state.map(query => {
                if (query.queryId !== queryId) {
                    return {...query}
                }
                return {
                    ...query,
                    loading: false,
                    response,
                }
            })
        }
        case SET_VEP_CHECK_ERROR: {
            const { queryId, message } = action

            return state.map(query => {
                if (query.queryId !== queryId) {
                    return {...query}
                }
                return {
                    ...query,
                    loading: false,
                    error: message,
                }
            })
        }
        default: {
            return state;
        }
    }
}
