import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {getEmployeeFilter, getEmployeesData} from '../../selectors/queries';
import {filterEmployee} from '../../actions';
import SelectInput from './SelectInput';
import oldMatcher from '../../utils/select2Matcher';
import {gettext} from "../../utils/text";


const EmployeeSelect = ({value, values, onSelectChange}) => {
    const options = {
        matcher: oldMatcher((term, text, option) => (
            text.toUpperCase().indexOf(term.toUpperCase()) >= 0 && typeof option.id !== 'undefined'
        )),
    };
    return (
        <SelectInput
            label={gettext("Employee")} values={values} value={value} onSelectChange={onSelectChange} options={options}
        />
    );
};

EmployeeSelect.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    values: PropTypes.array,
    onSelectChange: PropTypes.func,
};

const mapStateToProps = state => {
    const {employees, active} = getEmployeesData(state);
    const value = getEmployeeFilter(state);

    return {
        value: active.find(id => id === value) || 0,
        values: employees,
    };
};

export default connect(
    mapStateToProps,
    dispatch => ({
        onSelectChange: value => dispatch(filterEmployee(value)),
    }),
)(EmployeeSelect);
