export default function oldMatcher(matcher) {
    function wrappedMatcher(params, data) {
        const match = {...data};

        if (params.term == null || params.term.trim() === '') {
            return match;
        }

        if (data.children) {
            // Keep track of all of the children that matched the search term
            const matchingChildren = [];

            for (const child of data.children) {
                // Check if the child object matches
                // The old matcher returned a boolean true or false
                if (matcher(params.term, child.text, child)) {
                    matchingChildren.push(child);
                }
            }
            // If this optgroup had matching children, then return the optgroup but override its children
            // with the children, that matched the search term
            if (matchingChildren.length) {
                return {...match, children: matchingChildren};
            }
        }

        if (matcher(params.term, data.text, data)) {
            return match;
        }

        return null;
    }

    return wrappedMatcher;
}
