import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {DestinationShape} from './shapes';
import {removeDestination, removeSiteDestinationData, setSelectedDestination} from '../../actions';
import {getSelectedDestinations, getUpdatedDestinationsData} from '../../selectors/site_edit';
import {gettext} from "../../utils/text";
import {unsavedChangesMessage} from "../../widgets/site_edit";

const EditButton = ({ edited }) => {
    return (
        edited ? (
            <>
                <span className="icon icon-pencil--green" />
                {gettext("EDITED")}
            </>
        ) : (
            <>
                <span className="icon icon-pencil--gray" />
                {gettext("EDIT")}
            </>
        )
    );
}


const DestinationResults = ({destinations, updatedDestinations, onRemove, showModal}) => {
    React.useEffect(() => {
        if (updatedDestinations.length > 0) {
            window.onbeforeunload = () => unsavedChangesMessage;
        } else {
            window.onbeforeunload = null;
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [updatedDestinations]);

    const onRemoveConfirm = id => {
        window.onbeforeunload = () => {gettext("You have unsaved changes.")};
        onRemove(id);
    };

    const isEdited = id => {
        return Boolean(updatedDestinations.some(item => item.id === id));
    }

    const getContractSupply = (destination) => {
        const updatedDestination = updatedDestinations.find(item => item.id === destination.id);
        if (updatedDestination?.contract_supply) {
            return updatedDestination.contract_supply;
        } else {
            return destination.contract_supply;
        }
    }

    return (
        <table id="site-destinations" className="table table-vertical table-vertical--light">
            <thead>
            <tr>
                <th>{gettext("Destination")}</th>
                <th>{gettext("Recipient")}</th>
                <th>{gettext("Supply contract")}</th>
                <th />
            </tr>
            </thead>
            <tbody>
            {destinations.length ?
                destinations.map(destination => (
                    <tr key={destination.id}>
                        <td>{destination.destination}</td>
                        <td>{destination.receiver}</td>
                        <td>{getContractSupply(destination)}</td>
                        <td className="icon-container no-width">
                            <a onClick={() => showModal(destination)} className="command-link">
                                <EditButton edited={isEdited(destination.id)} />
                            </a>
                            <a onClick={() => onRemoveConfirm(destination.id)} className="command-link">
                                <span className="icon icon-remove" />{gettext("REMOVE")}
                            </a>
                        </td>
                    </tr>
                )) : <tr>
                    <td colSpan="3" className="text-left">{gettext("No destinations have been added to the site")}</td>
                </tr>}
            </tbody>
        </table>
    )
};

DestinationResults.propTypes = {
    destinations: PropTypes.arrayOf(DestinationShape),
    onRemove: PropTypes.func,
    showModal: PropTypes.func,
};

export default connect(
    state => ({
        destinations: getSelectedDestinations(state),
        updatedDestinations: getUpdatedDestinationsData(state),
    }),
    dispatch => ({
        onRemove: id => {
            dispatch(removeDestination(id));
            dispatch(removeSiteDestinationData(id));
        },
        showModal: destination => dispatch(setSelectedDestination(destination)),
    }),
)(DestinationResults);
