import {unsavedChangesMessage} from "../widgets/site_edit";

const triggerConfirmation = inputs =>
    Object.keys(inputs).forEach((key) => {
        [].forEach.call(document.querySelectorAll(inputs[key]), (i) => {
            i.addEventListener(key, () => {
                /*
                 the confirmation message of onbeforeunload can't be changed in most browsers (Chrome since 2015)
                 but we still need to return something in order to trigger it. ¯\_(ツ)_/¯
                 */
                window.onbeforeunload = () => unsavedChangesMessage;
            });
        })
    });

export default function triggerConfirmationForm() {
    const form = document.querySelector('.triggerConfirmationForm');

    // { eventHandler: [selectors] }
    const fields = {
        'change': [
            '.triggerConfirmationForm input[type=checkbox]',
            '.triggerConfirmationForm input[type=file]',
        ],
        'input': [
            '.triggerConfirmationForm input[type=text]',
            '.triggerConfirmationForm input[type=email]',
            '.triggerConfirmationForm input[type=number]',
        ],
    };

    // trigger confirmations on inputs
    triggerConfirmation(fields);

    // Select2 needs special treatment
    $('.select').on('change', () => window.onbeforeunload = () => unsavedChangesMessage);
    form.addEventListener('submit', () => {
        window.onbeforeunload = null; // don't trigger alert when form is submitted
    });
}
