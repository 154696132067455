import Router, {Resource} from "tg-resources";

import {getCookie} from './utils/cookie';


const api = new Router({
    assortment: new Resource(DJ_CONST.reverse.assortmentDetail("${pk}")),
    siteChanges: new Resource(DJ_CONST.reverse.siteChanges("${pk}")),
    refreshToken: new Resource(DJ_CONST.reverse.refreshToken()),
    sendPublicLink: new Resource(DJ_CONST.reverse.sendPublicLink()),
    setEmployee: new Resource(DJ_CONST.reverse.setEmployee()),
    waybillsDetail: new Resource(DJ_CONST.reverse.waybillsDetail(), {headers: {
        'X-CSRFToken': getCookie('csrftoken'),
        Accept: null,
    }}),
    queriesSearch: new Resource(DJ_CONST.reverse.queriesSearch()),
    waybillsSearch: new Resource(DJ_CONST.reverse.waybillsSearch()),
    coordinates: new Resource(DJ_CONST.reverse.coordinates()),
    recentWaybills: new Resource(DJ_CONST.reverse.recentWaybills()),
    tenderForm: new Resource(DJ_CONST.reverse.tenderForm("${tender_id}"), {headers: {
        Accept: null,
    }}),
    vepCheck: new Resource(DJ_CONST.reverse.vepCheck()),
}, {
    apiRoot: '',
    headers: () => ({
        'X-CSRFToken': getCookie('csrftoken'),
        accept: 'application/json',
    }),
});

export default api;
