import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {getManagerFilter, getManagersData} from '../../selectors/queries';
import {filterManager} from '../../actions';
import SelectInput from './SelectInput';
import {gettext} from "../../utils/text";


const ManagerSelect = ({value, values, onSelectChange}) => {
    const sites = values.map(item => ({id: item[0], text: item[1]}));
    return (
        <SelectInput
            label={gettext("Forest manager")}
            values={sites}
            value={value}
            onSelectChange={onSelectChange}
            helpText={gettext("Worklogs on forest manager's sites")}
        />
    );
};

ManagerSelect.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    values: PropTypes.array,
    onSelectChange: PropTypes.func,
};

export default connect(
    state => ({
        value: getManagerFilter(state),
        values: getManagersData(state),
    }),
    dispatch => ({
        onSelectChange: value => dispatch(filterManager(value)),
    }),
)(ManagerSelect);
