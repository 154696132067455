import {gettext} from "./text";

const message = gettext("Enter correct value.")

export const numericValueValidator = (value) => {
    if (!value || /^[0-9]+$/.test(value)) {
        return undefined;
    }
    return message;
};
export const cadastralNumberValidator = (value) => {
    if (!value || /^\d{5}:\d{3}:\d{4}(,\d{5}:\d{3}:\d{4})*$/.test(value)) {
        return undefined;
    }
    return message;
};
