import 'react-dates/initialize';
import $ from 'jquery';
import 'bootstrap-material-design';
import moment from 'moment';
import 'moment-range';
import L from 'leaflet';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import 'select2';
import 'select2/dist/js/i18n/et';
import template from 'lodash.template';
import queryString from 'query-string';
import debounce from 'lodash.debounce';
import 'leaflet.markercluster';

// Redux
import {Provider} from 'react-redux';
import {createStore, combineReducers} from 'redux';
import {reducer as reduxFormReducer} from 'redux-form';
import renderDatePicker from 'utils/datepicker';

import api from 'api';

// Actions
import {
    fetchAssortmentDetail,
    openAmountChanges,
    openSendPublicLinkModal,
    receivePublicLink,
    filterDate,
    setCompanies,
    setDestinations,
    setSites,
    setPartners,
    setEmployees,
    setManagers,
} from './actions';

// Reducers
import {
    assortmentReducer,
    settingsAPIReducer,
    changesReducer,
    siteInfoReducer,
    queriesFiltersReducers,
    queriesIsLoadingReducer,
    queriesModalAssortment,
    queriesDataReducers,
    queriesFocusDateInput,
    queriesFilterDataReducers,
    vepCheckReducer,
} from './reducers';

// Widgets
import outdatedBrowser from 'widgets/OutdatedBrowser';
import initSelect2 from 'widgets/Select2';
import {menuButtonToggle, fixedMenu} from 'widgets/Menu';
import initCountrySelects from 'widgets/CountrySelects';
import dropdownToggle from 'widgets/DropdownToggle';
import preload from 'widgets/Preload';
import changeEmployment from 'widgets/ChangeEmployment';
import settingsVehiclesFormSet from 'widgets/SettingsVehiclesFormSet';
import settingsCertificatesFormSet from 'widgets/SettingsCertificatesFormSet';
import partnersPage from 'widgets/PartnersPage';
import employeesPage from 'widgets/EmployeesPage';
import initForest from 'widgets/forest';
import {
    initSiteEditEmployments, initSiteEditReportReceivers,
    initSiteEditAttachments, initSiteEditDestinations,
    initSiteEditInfo, handleForestNoticeSelect,
} from 'widgets/site_edit';
import {tendersView} from './widgets/Tenders';
import initSmoothScroll from './widgets/SmoothScroll';
import {renderConnectedDestinationAdditionalProperties} from "./widgets/site_edit/DestinationAdditionalProperties";

// Components
import AssortmentDetailConnector from './components/AssortmentDetail';
import SiteChangesConnector from './components/SiteChangesModal';
import SettingsAPIConnector from './components/Settings';
import SiteInfoConnector from './components/SiteInfo';
import Waybills from './components/Waybills';
import Worklogs from './components/Worklogs';
import SearchForm from './components/queries/SearchForm';
import QueriesResult from './components/queries/QueriesResult';
import VEPCheckContainer from "./components/VEPCheckContainer";

// Utilities
import {getMiddleware, composeEnhancers} from './utils/middleware';
import {initConfirmation, refreshConfirms} from './utils/confirm';
import {toggleClass, addClass, removeClass} from './utils/classes';
import {findParent} from './utils/dom';
import {triggerNativeEvent} from './utils/events';
import {removeFormErrors} from './utils/forms';
import {openModal, closeModal} from './utils/modals';
import {createMap, blueIcon, greenIcon} from './utils/CreateMap';
import {initClosableWells} from './utils/wells';
import {setCookie} from "./utils/cookie";
import triggerConfirmationForm from './utils/confirmation';
import {bindFunctionToLinks} from "./utils/helpers";
import escapeHTML from "./utils/escapeHtml";
import {gettext} from "./utils/text";

// set moment locale
moment.locale('et');
moment.locale('et', {
    'invalidDate': gettext('Choose date'),
});

// Configure Sentry in deployed envs
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: DJ_CONST.SENTRY_DSN,
        environment: DJ_CONST.SENTRY_ENVIRONMENT,
    });

    // handle rejected promises
    window.addEventListener('unhandledrejection', (evt) => {
        Sentry.captureException(evt.reason);
    });
    // If we have authenticated user, pass its data on to Sentry
    if (DJ_CONST.user) {
        Sentry.configureScope((scope) => {
            scope.setUser({
                id: DJ_CONST.user.id,
                email: DJ_CONST.user.email,
                name: DJ_CONST.user.name,
            });
            Sentry.setContext('name', DJ_CONST.user.name);
        });
    }
}

function init() {
    HTMLCollection.prototype.forEach = Array.prototype.forEach;
    NodeList.prototype.forEach = Array.prototype.forEach;

    const maxWidthMenu = 1275;

    outdatedBrowser();
    preload();
    $.material.init();
    menuButtonToggle(maxWidthMenu);
    dropdownToggle();
    fixedMenu();
    changeEmployment();
    initConfirmation();
    initClosableWells();

    initSmoothScroll();
}

init();

/* Home Page VEP Check */
function initVEPCheck() {
    const elem = document.getElementById('vep-check-container');

    if (!elem) {
        return;
    }

    const reducers = combineReducers({
        form: reduxFormReducer,
        queries: vepCheckReducer,
    })
    const vepCheckStore = createStore(reducers, getMiddleware());

    ReactDOM.render(
        <Provider store={vepCheckStore}>
            <VEPCheckContainer />
        </Provider>,
        elem,
    );
}
/* End Home Page VEP Check */


function initDashboard(isMaster, partnershipID) {
    const container = document.getElementById('assortment_modal_content');

    const siteList = document.getElementById('site-list-select');

    if (siteList) {
        const checkBoxes = document.querySelectorAll('.checkboxinput.checkbox');
        if (checkBoxes.length) {
            const form = checkBoxes[0].form;
            const submit = debounce(() => form.submit(), 800);
            checkBoxes.forEach(box => box.addEventListener('change', submit));
        }
    }

    if (container) {
        const assortmentStore = createStore(assortmentReducer, getMiddleware());

        ReactDOM.render(
            <Provider store={assortmentStore}>
                <AssortmentDetailConnector is_master={isMaster} partnership_id={partnershipID} />
            </Provider>,
            container,
        );

        $('body').on('click', '.assortment-detail-modal', e => {
            const assortment = parseInt($(e.currentTarget).data('assortment'), 10);
            assortmentStore.dispatch(fetchAssortmentDetail(assortment, partnershipID));
        });

        $('body').on('click', '.assortment-detail-modal a', e => {
            e.preventDefault();
        });
    }
}

function initSiteBase() {
    const selector = '.site-select';
    initSelect2({
        selector: '.site-select',
        placeholder: null,
        width: 'auto',
    });

    $(selector).on("select2:select", (e) => {
        const siteID = parseInt(e.target.value, 10);
        window.location = DJ_CONST.reverse.site(siteID);
    });
}

function handleVisibleToSelectors() {
    const selectAll = document.getElementById('js-visible-to-select-all');
    const selectNone = document.getElementById('js-visible-to-select-none');
    const visibleTo = document.getElementById('id_tender-visible_to');

    if (selectAll && selectNone && visibleTo) {
        const allOptions = [...visibleTo.childNodes].map(o => o.value);
        const $select = $('#id_tender-visible_to');

        selectAll.onclick = e => $select.val(allOptions).trigger('change');
        selectNone.onclick = e => $select.val('').trigger('change');

        $select.on("change", e => {
            // If anything is selected, then $select.val() returns a list of selected values but when nothing is
            // selected, then instead of an empty list it returns null. Let's use [] instead of null in that case,
            // in order to keep it consistent.
            const selectedValue = $select.val() || [];

            toggleClass(selectAll, 'hide', selectedValue.length === allOptions.length);
            toggleClass(selectNone, 'hide', selectedValue.length === 0);
        });
        // Manually trigger an initial change event, so that the buttons get hidden according to the initial state.
        $select.trigger('change');
    }
}

function handleFieldsVisibility(isPublic) {
    const publicFields = document.getElementsByClassName('only-for-public');
    if (isPublic) {
        publicFields.forEach(f => removeClass(f, 'hide'));
    } else {
        publicFields.forEach(f => addClass(f, 'hide'));
    }
}

function initSiteTenders(tenderIsEditable, deadlineIsReadonly) {
    initSelect2({
        selector: 'select',
        placeholder: 'Vali ettevõte',
    });

    function setFocusOnComment() {
         document.getElementById('id_tender-comment').focus();
    }

    const tendersModal = document.getElementById('tenders-form-modal');
    if (tendersModal) {
        if (tenderIsEditable) {
            initSelect2({});
        }
        renderDatePicker('tender-deadline', 'Vali', true, deadlineIsReadonly);
        renderDatePicker('tender-work_deadline', 'Vali', false);

        const isPublicField = document.getElementById('id_tender-is_public');
        // When the tender isn't editable anymore we want to always display the deadline
        handleFieldsVisibility(isPublicField.checked || !tenderIsEditable);

        isPublicField.onclick = () => {
            handleFieldsVisibility(isPublicField.checked);
        };

        const hasErrors = tendersModal.getElementsByClassName('has-error').length > 0 ||
                tendersModal.getElementsByClassName('alert-danger').length > 0;
        if (hasErrors) {
            openModal(tendersModal);
            if (!tenderIsEditable) {
                 setFocusOnComment();
            }
        }

        const changeLinks = document.getElementsByClassName('js-open-tenders-modal');
        for (let i = changeLinks.length - 1; i >= 0; i--) {
            changeLinks[i].onclick = (e) => {
                e.preventDefault();
                const amountsID = parseInt(changeLinks[i].getAttribute('data-amounts-id'), 10);
                if (!isNaN(amountsID)) {
                    openModal(tendersModal);
                    if (!tenderIsEditable) {
                        setFocusOnComment();
                    }
                }
            };
        }
    }

    handleVisibleToSelectors();
}


function initSiteDetail(siteID, tenderIsEditable, deadlineIsReadonly) {
    initSiteBase();
    initSiteTenders(tenderIsEditable, deadlineIsReadonly);

    const changesModalContainer = document.getElementById('changes-modal-container');

    if (changesModalContainer) {
        const changesStore = createStore(changesReducer, getMiddleware());

        ReactDOM.render(
            <Provider store={changesStore}>
                <SiteChangesConnector />
            </Provider>,
            changesModalContainer,
        );

        const amountChangesLinks = document.querySelectorAll('.table-assortments .amount-changes');
        for (let i = amountChangesLinks.length - 1; i >= 0; i--) {
            amountChangesLinks[i].onclick = (e) => {
                e.preventDefault();

                const amountsID = $(e.currentTarget).data('amounts-id');
                if (isNaN(amountsID)) {
                    return;
                }

                changesStore.dispatch(openAmountChanges(siteID, amountsID));
            };
        }

        const allAmountChangesLink = document.getElementById('all-amount-changes');
        if (allAmountChangesLink !== null) {
            allAmountChangesLink.onclick = (e) => {
                e.preventDefault();

                changesStore.dispatch(openAmountChanges(siteID));
            };
        }
    }

    const changeFormModal = document.getElementById('change-form-modal');

    if (changeFormModal) {
        const hasErrors = changeFormModal.getElementsByClassName('has-error').length > 0;
        if (hasErrors) {
            openModal(changeFormModal);
        }

        const changeLinks = document.getElementsByClassName('change-amounts');
        for (let i = changeLinks.length - 1; i >= 0; i--) {
            const row = findParent(changeLinks[i], (el) => el.nodeName.toLocaleLowerCase() === 'tr');

            changeLinks[i].onclick = (e) => {
                e.preventDefault();

                const amountsID = parseInt(changeLinks[i].getAttribute('data-amounts-id'), 10);
                const assortmentName = changeLinks[i].text.trim();

                if (!isNaN(amountsID)) {
                    removeFormErrors(changeFormModal);

                    document.getElementById('assortment-name').innerHTML = assortmentName;
                    document.getElementById('amounts-id').value = amountsID;

                    const attrList = ['metaks', 'chopped', 'transported', 'shipped', 'confirmed'];
                    for (let j = attrList.length - 1; j >= 0; j--) {
                        const input = document.getElementById(`id_${attrList[j]}`);
                        input.value = row.getElementsByClassName(attrList[j])[0].innerHTML.trim();
                        triggerNativeEvent(input, 'change', false);
                    }

                    const textarea = document.getElementById('id_comment');
                    textarea.value = '';
                    triggerNativeEvent(textarea, 'change', true);

                    openModal(changeFormModal);
                }
            };
        }
    }

    const showChoppedPercentageButton = document.getElementById('js_show_chopped_ratio');
    showChoppedPercentageButton.onclick = () => {
        // Save it to cookies
        setCookie('show_chopped_percentages', showChoppedPercentageButton.checked, 30);

        // Toggle 'hidden' class on 'chopped-percentage' elements based on the checkbox'es value
        const choppedPercentageFields = document.getElementsByClassName('chopped-percentage');
        choppedPercentageFields.forEach(field => {
            toggleClass(field, 'hidden', !showChoppedPercentageButton.checked);
        });

        // Change the colSpan value of "regular" chopped elements based on the checkbox' value
        const choppedFields = document.getElementsByClassName('chopped');
        const colSpan = showChoppedPercentageButton.checked ? 1 : 2;
        choppedFields.forEach(field => {
            field.colSpan = colSpan;
        });
    };
}

function sendPublicLinkModal(e, siteInfoStore, siteId, link, linkType) {
    e.preventDefault();
    siteInfoStore.dispatch(receivePublicLink(link, siteId, linkType));
    siteInfoStore.dispatch(openSendPublicLinkModal());
}

function initSiteInfoView(lat, lng, publicLink, originDocsLink, siteId) {
    initSiteBase();

    const container = document.getElementById('react-container');

    if (container) {
        const siteInfoStore = createStore(siteInfoReducer(), getMiddleware());

        ReactDOM.render(
            <Provider store={siteInfoStore}>
                <SiteInfoConnector />
            </Provider>,
            container,
        );
        const body = $('body');
        body.on('click', '.public-link-modal', e => {
            sendPublicLinkModal(e, siteInfoStore, siteId, publicLink, 'public_link');
        });
        body.on('click', '.public-origin-docs-link-modal', e => {
            sendPublicLinkModal(e, siteInfoStore, siteId, originDocsLink, 'origin_documents');
        });
    }


    // If the Site has location, create map, center on that and zoom close
    const hasLocation = !isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng));

    if (hasLocation) {
        const map = createMap('site-location-map');
        map.setView([lat, lng], 15);
        L.marker([lat, lng]).addTo(map);
    }
}

function initSiteWorkLogs() {
    initSiteBase();

    const container = document.getElementById('worklog_container');

    if (container) {
        ReactDOM.render(
            <Worklogs />,
            container,
        );
    }
}

function initWaybills() {
    const container = document.getElementById('waybills');

    if (container) {
        ReactDOM.render(
            <Waybills />,
            container,
        );
    }
}

function initSiteWaybills(waybillsData, redirect) {
    initSiteBase();
    const redirectUrlParam = redirect ? `&redirect=${encodeURIComponent(redirect)}` : "";

    // Add Waybill functionality - modal is shown when the button is clicked
    const addWaybillBtn = document.getElementById('waybill-add-btn');
    const addWaybillModal = document.getElementById('waybill-add-modal');
    if (addWaybillBtn) {
        addWaybillBtn.onclick = e => {
            e.preventDefault();
            openModal(addWaybillModal);
        };
    }
    // Modal is also shown when it contains errors (= was submitted with errors)
    if (addWaybillModal) {
        const addWaybillModalHasErrors = addWaybillModal.getElementsByClassName('has-error').length > 0;
        if (addWaybillModalHasErrors) {
            openModal(addWaybillModal);
        }
    }
    const waybillDetailsModal = document.getElementById('waybill-details-modal');
    const wayBillInfo = document.getElementById('waybill-info');

    function initWaybillModal(waybillId) {
        // after the ajax content load refresh the confirms
        refreshConfirms();

        // Show the modal
        openModal(waybillDetailsModal);

         // TODO: We should make a POST request here, but the server doesn't support it yet :(
        const cancelURL = `${DJ_CONST.reverse.waybillsCancel(waybillId)}${redirectUrlParam}`;
        const cancelBtn = document.getElementById('waybill-cancel-btn');
        if (cancelBtn) {
            cancelBtn.setAttribute('href', cancelURL);
        }

        const pdfURL = `${DJ_CONST.reverse.waybill_pdf(waybillId)}`;
        const pdfBtn = document.getElementById('waybill-pdf-btn');
        if (pdfBtn) {
            pdfBtn.setAttribute('href', pdfURL);
        }

        const closeBtn = document.getElementById('waybill-close-btn');
        if (closeBtn) {
            closeBtn.onclick = () => closeModal(waybillDetailsModal);
        }

        async function moveWaybill() {
            closeModal(waybillDetailsModal);
            const waybillID = document.getElementById('waybill-id').value;

            const data = {comment: document.getElementById('id_comment').value};
            try {
                const response = await api.waybillsDetail.post(null, data, {id: waybillID});

                if (response.length > 0) {
                    wayBillInfo.innerHTML = response;
                    initWaybillModal(waybillId);
                } else {
                    if (redirect) {
                        window.location = redirect;
                    } else {
                        location.reload();
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }
        const moveWaybillBtn = document.getElementById('waybill-move-btn');
        if (moveWaybillBtn) {
            moveWaybillBtn.onclick = (e) => {
                e.preventDefault();
                moveWaybill();
            };
        }
    }

    // Waybill details modal
    async function showWaybillModal(waybillId) {
        const waybills = waybillsData || DJ_CONST.WAYBILLS_DATA;
        const waybillData = waybills[waybillId];

        // Replace data in form fields and labels
        wayBillInfo.innerHTML = '';
        waybillDetailsModal.getElementsByClassName('tg-modal-title')[0].innerHTML = waybillData.number;
        waybillDetailsModal.getElementsByClassName('waybill-status')[0].innerHTML = waybillData.status;

        // Asyncly load the main content of the modal
        try {
            const response = await api.waybillsDetail.fetch(null, {id: waybillData.id});
            wayBillInfo.innerHTML = response;
        } catch (e) {
            console.error(e);
        }

        initWaybillModal(waybillId);
    }

    const waybillItems = document.getElementsByClassName('waybill-clickable');
    for (let i = 0; i < waybillItems.length; i++) {
        const itemElement = waybillItems[i];
        itemElement.onclick = e => {
            e.preventDefault();
            showWaybillModal(itemElement.dataset.waybillId);
        };
    }
}

function initSiteMaps() {
    const map = createMap('map');
    const clusters = L.markerClusterGroup();
    const markers = {};

    DJ_CONST.SITES.forEach(site => {
        const marker = L.marker([site.lat, site.lng]);
        const forestManager = site.forest_manager ? `<span>${site.forest_manager}</span><br>` : '';
        const siteUrl = site.url ? `<a href="${site.url}">${gettext("Open in Vaheladu")}</a><br>` : '';
        const popupHtml = `
            <strong>${site.name}</strong> - ${site.status_name}<br>
            ${forestManager}
            ${siteUrl}
            <a href="${site.location_link_url}" target="_blank">${gettext("See location on the map")}</a><br>
            <a href="${site.location_link_google_maps_url}" target="_blank">${gettext("See location in Google Maps")}</a>
        `;
        marker.bindPopup(popupHtml);
        markers[site.id] = marker;
        clusters.addLayer(marker);
    });

    map.fitBounds(DJ_CONST.SITES.map(site => [site.lat, site.lng]), {
        maxZoom: 15,
        padding: [50, 50],
    });

    map.addLayer(clusters);

    const onStatusLinkClick = link => () => {
        const status = parseInt(link.getAttribute('data-status-key'), 10);

        DJ_CONST.SITES.forEach(site => {
            const marker = markers[site.id];

            if (site.status_key === status) {
                if (link.checked) {
                    clusters.addLayer(marker);
                } else {
                    clusters.removeLayer(marker);
                }
            }
        });
    };

    const statusLinks = document.querySelectorAll('[data-status-key]');
    for (const statusLink of statusLinks) {
        statusLink.onchange = onStatusLinkClick(statusLink);
    }
}

function initTenderMaps() {
    if (!DJ_CONST.SITES.length) {
        return;
    }

    const map = createMap('map');
    const markers = {};

    const markTenderRow = (tenderId) => {
        const tenderRows = document.querySelectorAll('.js-tender-rows');
        for (const tenderTr of tenderRows) {
            toggleClass(tenderTr, 'info', tenderId === parseInt(tenderTr.getAttribute('data-tender-id'), 10));
        }
    };

    const markTenderMarker = (tenderId) => {
        const marker = markers[tenderId];
        if (marker) {
            marker.fireEvent('click');
        }
    };

    const tenderRows = document.querySelectorAll('.js-tender-rows');
    tenderRows.forEach(tenderTr => {
        const tenderId = parseInt(tenderTr.getAttribute('data-tender-id'), 10);
        tenderTr.addEventListener('click', () => markTenderMarker(tenderId));
    });

    DJ_CONST.SITES.forEach(site => {
        const icon = site.tender_on_site ? greenIcon : blueIcon;
        const marker = L.marker([site.lat, site.lng], {icon}).addTo(map);
        const popupHtml = `<strong>${site.name}</strong> - ${site.tender_location_display}<br>` +
            `<a href="${site.url}">${gettext("Open in Vaheladu")}</a><br>` +
            `<a href="${site.location_link_url}" target="_blank">${gettext("See location on the map")}</a><br>` +
            `<a href="${site.location_link_google_maps_url}" target="_blank">${gettext("See location in Google Maps")}</a>`;
        marker.bindPopup(popupHtml);
        marker.on('click', () => markTenderRow(site.tender_id));
        markers[site.tender_id] = marker;
    });

    map.fitBounds(DJ_CONST.SITES.map(site => [site.lat, site.lng]), {
        maxZoom: 15,
        padding: [50, 50],
    });
}

function initSettingsDetails() {
    initCountrySelects('#id_country_code', '#id_county', '#id_city');
    initSelect2({placeholder: ''});
}

function initSettingsDestinations() {
    const { setDestinationAdditionalPropertiesValues } = renderConnectedDestinationAdditionalProperties()
    initCountrySelects('#id_country_code', '#id_county', '#id_city');
    initCountrySelects('#id_receiver_country_code', '#id_receiver_county', '#id_receiver_city');
    initSelect2({placeholder: ''});

    const destinationsFormModal = document.getElementById('destination-form-modal');
    const addDestinationButton = document.getElementById('add-destination');
    const selectAllAssortmentsButton = document.getElementById('js-select-all-assortments');

    const formFields = {
        id_name: 'name',
        id_code: 'code',
        id_county: 'county',
        id_city: 'city',
        id_street: 'street',
        id_contact_name: 'contact_name',
        id_contact_email: 'contact_email',
        id_contact_phone: 'contact_phone',
        id_receiver_name: 'receiver_name',
        id_receiver_code: 'receiver_code',
        id_receiver_county: 'receiver_county',
        id_receiver_city: 'receiver_city',
        id_receiver_street: 'receiver_street',
    };

    const openDestinationModal = (destination, resetForm) => {
        if (resetForm) {
            removeFormErrors(destinationsFormModal);
        }

        // Set the modal's title and the hidden input for destination's ID

        document.getElementById('modal-title').innerHTML = destination === null ? gettext("Add destination") : gettext("Edit destination");
        document.getElementById('destination-id').value = destination === null ? '' : destination.id;

        // Initial values for the regular form fields (we deal with certificates and assortments below)

        if (resetForm) {
            // uncheck destination check box when it opens (same as for the rest checkboxes)
            // and check if destination is inactive
            const checkbox = document.querySelector(`input[name$="inactive"]`);
            checkbox.checked = !!(destination && destination.inactive);

            Object.keys(formFields).forEach((key) => {
                const field = document.getElementById(key);
                field.value = destination === null ? '' : destination[formFields[key]];
                triggerNativeEvent(field, 'change', destination === null);
            });

            /* Additional properties field is managed by self-contained react component, so it is impossible
            to simply set the value on input. Instead, the setter funciton has to be used. */
            setDestinationAdditionalPropertiesValues(
                destination === null ? null : destination["additional_properties"],
            )
        }

        // Initial values for the assortment checkboxes. They will be all unchecked when creating a new destination.

        if (resetForm) {
            const assortmentInputs = document.querySelectorAll('input[name*="assortment_"]');
            for (const assortmentInput of assortmentInputs) {
                assortmentInput.checked = false;
            }
            if (destination !== null) {
                const selectedAssortments = destination.assortments;
                for (const assortmentID of selectedAssortments) {
                    const assortmentInput = document.querySelector(`input[name$="assortment_${assortmentID}"]`);
                    /*
                    If in the database a non-baseassortment is marked as accepted by this destination, then it is
                    included in `destination.assortments` but input for that assortment isn't generated and an error
                    is raised.

                    Ideally, it shouldn't even be an issue, because a destination should only accept baseassortments.
                     */
                    if (assortmentInput != null) {
                        assortmentInput.checked = true;
                    }
                }
            }
        }

        // Initial values for the certificate checkboxes.

        const certificateFormGroup = input => input.parentNode.parentNode.parentNode;
        const certificateInputs = document.querySelectorAll('input[name*="cert_"]');
        for (const certificateInput of certificateInputs) {
            toggleClass(certificateFormGroup(certificateInput), 'hidden', true);
            if (resetForm) {
                certificateInput.checked = false;
            }
        }

        const checkCertificates = (visibleCertificates, checkedCertificates) => {
            const certificateInfo = document.getElementById('certificate-info');
            toggleClass(certificateInfo, 'hidden', visibleCertificates.length !== 0);

            for (const certificateID of visibleCertificates) {
                const certificateInput = document.querySelector(`input[name$="cert_${certificateID}"]`);
                toggleClass(certificateFormGroup(certificateInput), 'hidden', false);
            }
            if (resetForm) {
                for (const certificateID of checkedCertificates) {
                    const certificateInput = document.querySelector(`input[name$="cert_${certificateID}"]`);
                    certificateInput.checked = true;
                }
            }
        };

        if (destination === null) {
            const certificateString = addDestinationButton.getAttribute('data-company-certificates');
            const visibleCertificates = certificateString.length > 0 ? certificateString.split(',') : [];
            checkCertificates(visibleCertificates, visibleCertificates);
        } else {
            checkCertificates(destination.available_certificates, destination.certificates);
        }

        // Finally open the actual modal

        openModal(destinationsFormModal);
    };

    if (destinationsFormModal) {
        const hasErrors = destinationsFormModal.getElementsByClassName('has-error').length > 0;
        if (hasErrors) {
            const destinationID = parseInt(document.getElementById('destination-id').value, 10);
            openDestinationModal(isNaN(destinationID) ? null : DJ_CONST.destinations[destinationID], false);
        }

        addDestinationButton.onclick = (e) => {
            e.preventDefault();
            openDestinationModal(null, true);
        };

        const changeLinks = document.getElementsByClassName('js-edit-destination');
        for (let i = changeLinks.length - 1; i >= 0; i--) {
            changeLinks[i].onclick = (e) => {
                e.preventDefault();

                const destinationID = parseInt(changeLinks[i].getAttribute('data-id'), 10);

                if (!isNaN(destinationID)) {
                    openDestinationModal(DJ_CONST.destinations[destinationID], true);
                }
            };
        }
    }
    selectAllAssortmentsButton.onclick = e => {
        e.preventDefault();
        const assortmentInputs = document.querySelectorAll('input[name*="assortment_"]');

        assortmentInputs.forEach(input => {
            input.checked = true;
        });
    };
}

function initSettingsVehicles() {
    settingsVehiclesFormSet();
}

function initSettingsCertificates() {
    settingsCertificatesFormSet();
}

function initSettingsAPI(userToken) {
    const container = document.getElementById('api_container');

    if (container) {
        const settingsAPIStore = createStore(settingsAPIReducer(userToken), getMiddleware());

        ReactDOM.render(
            <Provider store={settingsAPIStore}>
                <SettingsAPIConnector />
            </Provider>,
            container,
        );
    }
}

function initQueries(isMaster) {
    const searchContainer = document.getElementById('queries-search-form');
    const resultsContainer = document.getElementById('queries-results');

    if (searchContainer && resultsContainer) {
        const reducers = combineReducers({
            filters: queriesFiltersReducers,
            filtersData: queriesFilterDataReducers,
            isLoading: queriesIsLoadingReducer,
            queries: queriesDataReducers,
            focusInput: queriesFocusDateInput,
            selectedModalAssortmentId: queriesModalAssortment,
        });
        const store = createStore(reducers, composeEnhancers(getMiddleware()));

        const dateRange = moment().range("month");
        store.dispatch(filterDate(dateRange));

        // change to api fetch at later point
        store.dispatch(setCompanies(DJ_CONST.COMPANY_CHOICES));
        store.dispatch(setDestinations(DJ_CONST.DESTINATION_CHOICES));
        store.dispatch(setSites(DJ_CONST.SITE_CHOICES));
        store.dispatch(setEmployees(DJ_CONST.EMPLOYEE_CHOICES));
        store.dispatch(setPartners(DJ_CONST.PARTNER_CHOICES));
        store.dispatch(setManagers(DJ_CONST.MANAGER_CHOICES));

        ReactDOM.render(
            <Provider store={store}>
                <SearchForm isMaster={isMaster} />
            </Provider>,
            searchContainer,
        );

        ReactDOM.render(
            <Provider store={store}>
                <QueriesResult isMaster={isMaster} />
            </Provider>,
            resultsContainer,
        );
    }
}

function initAssortmentsPage(invokeMergeConfirmation) {
    const assortmentsFormModal = document.getElementById('assortments-form-modal');
    const companyAssortmentId = document.getElementById('company_assortment_id').value;

    initSelect2({placeholder: null});

    const openAssortmentModal = (assortment, removeErrors = true) => {
        if (removeErrors) {
            removeFormErrors(assortmentsFormModal);
        }

        if (assortment === null) {
            throw new Error(gettext("Assortment was not defined when trying to open modal"));
        }

        document.getElementById('company_assortment_assortment').innerHTML = assortment.name;
        document.getElementById('company_assortment_company_name').innerHTML = assortment.companyName;
        document.getElementById('company_assortment_assortment_elvis_id').innerHTML = assortment.elvisId;
        document.getElementById('company_assortment_assortment_elvis_code').innerHTML = assortment.elvisCode;
        // Set the force_merge always to false, so that the confirmation doesn't get transferred to other assortments.
        document.getElementById('id_force_merge').value = false;

        // don't override values if not removing errors (had problem saving)
        if (removeErrors) {
            document.getElementById('company_assortment_id').value = assortment.id;
            const orderField = document.getElementById('id_order');
            orderField.value = assortment.order;
            triggerNativeEvent(orderField, 'change', false);
            const baseAssortmentElement = document.getElementById('id_base_assortment');
            baseAssortmentElement.value = assortment.baseAssortment;
            triggerNativeEvent(baseAssortmentElement, 'change', false);
        }

        openModal(assortmentsFormModal);
    };

    if (assortmentsFormModal) {
        // parse link data attr's
        const getAssortmentData = changeLink => ({
            id: parseInt(changeLink.getAttribute('data-id'), 10),
            name: changeLink.getAttribute('data-assortment-name'),
            companyName: changeLink.getAttribute('data-company-name'),
            order: changeLink.getAttribute('data-order'),
            baseAssortment: changeLink.getAttribute('data-base-assortment') || 'None',
            elvisId: changeLink.getAttribute('data-assortment-elvis-id'),
            elvisCode: changeLink.getAttribute('data-assortment-elvis-code'),
        });

        // open if there was errors
        const hasErrors = assortmentsFormModal.getElementsByClassName('has-error').length > 0;
        if (hasErrors && companyAssortmentId) {
            const errorChangeLink = document.getElementById(`change-assortment-data-${companyAssortmentId}`);
            openAssortmentModal(getAssortmentData(errorChangeLink), false);
        }

        // callback factory
        const changeCallbackFactory = changeLink => e => {
            e.preventDefault();
            openAssortmentModal(getAssortmentData(changeLink));
        };

        // add link callback
        const changeLinks = document.getElementsByClassName('js-change-assortment');
        for (const changeLink of Array.from(changeLinks)) {
            changeLink.onclick = changeCallbackFactory(changeLink);
        }
    }

    // If `invokeMergeConfirmation` is provided us by Django, then we have to display the confirmation dialog
    if (invokeMergeConfirmation) {
        // Set force_merge to true, so that when user confirms it, the form will be posted with force_merge = True.
        // If the user doesn't confirm it, then the force_merge is reset to False when the form/modal is opened
        const forceMergeInput = document.getElementById('id_force_merge');
        forceMergeInput.value = true;

        // Trigger the beautiful alert by clicking the hidden anchor
        document.getElementById('js-confirm-assortment-merge').click();
    }
}

function initSiteListModal(SITES_METADATA) {
    function openInfoModal(e) {
        const siteId = e.getAttribute('data-site-id');
        const selectedSite = SITES_METADATA.find(x => x.id === parseInt(siteId, 10));

        const modal = document.getElementById('siteFilesModal');
        const modalTitle = document.querySelector('.siteForestNotice');
        const modalContent = document.querySelector('.siteFiles');

        // clear values from previous modal
        modalContent.innerHTML = '';

        modalTitle.innerText = `Lank: ${selectedSite.name}`;

        modalContent.innerHTML +=
            `<div class="container">
                 <div class="row">
                     <b>${gettext("Address")}:</b> ${selectedSite.address}
                 </div>
                 </br>
                 <div class="row">
                     <b>${gettext("Cadastral reference")}:</b> ${selectedSite.cadaster_id}
                 </div>
                 </br>
                 <div class="row">
                     <b>${gettext("Land Register No")}:</b> ${selectedSite.land_register_number}
                 </div>
                 </br>
            </div>`;


        modalContent.innerHTML +=
            selectedSite.notice_numbers.map(n =>
                `<div class="site-attachment-new">
                        <span class="icon-container">
                            <span class="icon icon-map"></span>
                        </span>
                        <span class="text-left site-attachment">
                            <div>
                                Metsateatis nr. ${escapeHTML(n.number)}
                            </div>
                        </span>
                    </div>`
            ).join('');

        modalContent.innerHTML +=
            selectedSite.attachments.map(a =>
                `<div class="site-attachment-new">
                        <span class="icon-container">
                            <span class="icon icon-document-xs"></span>
                        </span>
                        <span class="text-left site-attachment">
                            <a
                                href="${a.url}"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                ${escapeHTML(a.name)}
                            </a>
                        </span>
                    </div>`
            ).join('');

        openModal(modal);
    }
    bindFunctionToLinks('js-open-info-modal', openInfoModal);
}

function initSiteList(SITES_METADATA, initialRecentInProgressWaybills) {
    initSiteListModal(SITES_METADATA);

    const query = queryString.parse(window.location.search);
    if (query.timed_logout === 'true') {
        // Logout after 1 minute,
        // might be extended from the users point of view due to page reload if recent waybills change
        setTimeout(() => {
            window.location = DJ_CONST.reverse.logout();
        }, 60000);
    }

    // Check for waybill status changes every 9s and
    // refresh if any in progress waybills have changed status
    const waybillCheckTimeout = 9000;  // 9s should allow checking multiple times before the logout occurs
    const checkRecentWaybills = () => {
        api.recentWaybills.fetch().then((newRecentWaybills) => {
            const diff = initialRecentInProgressWaybills.some(
                x => newRecentWaybills.indexOf(x) === -1
            );
            if (diff) {
                location.reload();
            }
        }).finally(() => setTimeout(checkRecentWaybills, waybillCheckTimeout));
    };
    if (!!initialRecentInProgressWaybills.length) {
        setTimeout(checkRecentWaybills, waybillCheckTimeout);
    }
}

export {
    init, initVEPCheck, initDashboard,
    initSiteDetail, initSiteInfoView, initSiteWorkLogs, initWaybills, initSiteWaybills, initSiteMaps,
    initSettingsDetails, initSettingsDestinations, initSettingsVehicles, initSettingsCertificates, initSettingsAPI,
    initQueries,
    initAssortmentsPage,
    initSiteEditInfo, initSiteEditAttachments, initSiteEditDestinations,
    initSiteEditReportReceivers, initSiteEditEmployments,
    partnersPage,
    employeesPage,
    initForest,
    tendersView,
    initTenderMaps,
    initSiteList,
    triggerConfirmationForm,

    handleVisibleToSelectors,
    handleFieldsVisibility,
    handleForestNoticeSelect,
};
