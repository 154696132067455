import $ from 'jquery';

function ChangeEmployment() {
    function setCookie(name, value, exdays) {
        const exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);

        const expires = (exdays == null) ? "" : `; expires="${exdate.toUTCString()}`;
        const cValue = `${encodeURIComponent(value)}; path=/${expires}`;
        document.cookie = `${name}=${cValue}`;
    }

    $(".change-employment").on('click', function onClick() {
        setCookie("selected_employment", $(this).data('employment'));
        location.href = "/";
        return false;
    });
}

export default ChangeEmployment;
