import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {getCompanyFilter, getCompaniesData} from '../../selectors/queries';
import {filterCompany} from '../../actions';
import SelectInput from './SelectInput';
import {gettext} from "../../utils/text";

const CompanySelect = ({value, values, isMaster, onSelectChange}) => {
    const companies = values.map(item => ({id: item[0], text: item[1]}));
    const helpText = isMaster ? null : gettext("The work of your employees on the company's site, and the company's work on your site");
    return (
            <SelectInput
                label={gettext("Company")}
                values={companies}
                value={value}
                onSelectChange={onSelectChange}
                helpText={helpText}
            />
    );
};

CompanySelect.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    values: PropTypes.array,
    onSelectChange: PropTypes.func,
};


export default connect(
    state => ({
        value: getCompanyFilter(state),
        values: getCompaniesData(state),
    }),
    dispatch => ({
        onSelectChange: value => dispatch(filterCompany(value)),
    }),
)(CompanySelect);
