import api from 'api';
import { v4 as uuidv4 } from 'uuid';
import { getQueryParameters } from './selectors/queries';

// Actions types

export const ACTION_OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const ACTION_CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL';

export const ACTION_FETCH_ASSORTMENT_DETAIL = 'FETCH_ASSORTMENT_DETAIL';
export const ACTION_ASSORTMENT_DETAIL_RESULTS = 'ASSORTMENT_DETAIL_RESULTS';
export const ACTION_CLOSE_ASSORTMENT_DETAIL = 'CLOSE_ASSORTMENT_DETAIL';

export const ACTION_OPEN_AMOUNT_CHANGES = 'OPEN_AMOUNT_CHANGES';
export const ACTION_FETCHED_AMOUNT_CHANGES = 'FETCHED_AMOUNT_CHANGES';
export const ACTION_CLOSE_AMOUNT_CHANGES = 'CLOSE_AMOUNT_CHANGES';

export const ACTION_REFRESH_API_TOKEN = 'REFRESH_API_TOKEN';
export const ACTION_API_TOKEN_REFRESHED = 'API_TOKEN_REFRESHED';

export const ACTION_OPEN_SEND_PUBLIC_LINK_MODAL = 'OPEN_SEND_PUBLIC_LINK_MODAL';
export const ACTION_CLOSE_SEND_PUBLIC_LINK_MODAL = 'CLOSE_SEND_PUBLIC_LINK_MODAL';
export const ACTON_RECEIVE_PUBLIC_LINK = 'RECEIVE_PUBLIC_LINK';
export const ACTION_SHOW_SEND_PUBLIC_LINK_SUCCESS = 'SHOW_SEND_PUBLIC_LINK_SUCCESS';
export const ACTION_HIDE_SEND_PUBLIC_LINK_MESSAGES = 'HIDE_SEND_PUBLIC_LINK_MESSAGES';
export const ACTION_SHOW_SEND_PUBLIC_LINK_ERROR = 'SHOW_SEND_PUBLIC_LINK_ERROR';

export const ACTION_QUERIES_RESULTS_ASSORTMENTS = 'ACTION_QUERIES_RESULTS_ASSORTMENTS';
export const ACTION_QUERIES_IS_LOADING = 'ACTION_QUERIES_IS_LOADING';
export const ACTION_QUERIES_MODAL_ASSORTMENT = 'ACTION_QUERIES_MODAL_ASSORTMENT';

export const ACTION_QUERIES_FOCUS_DATE_INPUT = 'ACTION_QUERIES_FOCUS_DATE_INPUT';
export const ACTION_QUERIES_LOAD_COMPANIES = 'ACTION_QUERIES_LOAD_COMPANIES';
export const ACTION_QUERIES_LOAD_DESTINATIONS = 'ACTION_QUERIES_LOAD_DESTINATIONS';
export const ACTION_QUERIES_LOAD_SITES = 'ACTION_QUERIES_LOAD_SITES';
export const ACTION_QUERIES_LOAD_EMPLOYEES = 'ACTION_QUERIES_LOAD_EMPLOYEES';
export const ACTION_QUERIES_LOAD_PARTNERS = 'ACTION_QUERIES_LOAD_PARTNERS';
export const ACTION_QUERIES_LOAD_MANAGERS = 'ACTION_QUERIES_LOAD_MANAGERS';

export const ACTION_QUERIES_FILTER_DATE = 'ACTION_QUERIES_FILTER_DATE';
export const ACTION_QUERIES_FILTER_COMPANY = 'ACTION_QUERIES_FILTER_COMPANY';
export const ACTION_QUERIES_FILTER_DESTINATION = 'ACTION_QUERIES_FILTER_DESTINATION';
export const ACTION_QUERIES_FILTER_SITE = 'ACTION_QUERIES_FILTER_SITE';
export const ACTION_QUERIES_FILTER_EMPLOYEE = 'ACTION_QUERIES_FILTER_EMPLOYEE';
export const ACTION_QUERIES_FILTER_PARTNER = 'ACTION_QUERIES_FILTER_PARTNER';
export const ACTION_QUERIES_FILTER_MANAGER = 'ACTION_QUERIES_FILTER_MANAGER';

export const ACTION_SITE_EDIT_DESTINATIONS_SELECT = 'ACTION_SITE_EDIT_DESTINATIONS_SELECT';
export const ACTION_SITE_EDIT_DESTINATIONS_REMOVE = 'ACTION_SITE_EDIT_DESTINATIONS_REMOVE';
export const ACTION_SITE_EDIT_SET_MASTER_DESTINATIONS = 'ACTION_SITE_EDIT_SET_MASTER_DESTINATIONS';
export const ACTION_SITE_EDIT_SET_MY_DESTINATIONS = 'ACTION_SITE_EDIT_SET_MY_DESTINATIONS';
export const ACTION_SITE_EDIT_DESTINATIONS_INFO_MODAL = 'ACTION_SITE_EDIT_DESTINATIONS_INFO_MODAL';
export const ACTION_SITE_EDIT_SET_INACTIVE_DESTINATIONS = 'ACTION_SITE_EDIT_SET_INACTIVE_DESTINATIONS';
export const ACTION_SITE_EDIT_UPDATE_DESTINATION_ADDITIONAL_DATA = 'ACTION_SITE_EDIT_UPDATE_DESTINATION_ADDITIONAL_DATA';
export const ACTION_SITE_EDIT_REMOVE_DESTINATION_ADDITIONAL_DATA = 'ACTION_SITE_EDIT_REMOVE_DESTINATION_ADDITIONAL_DATA';
export const ACTION_SITE_REVERT_DESTINATION_ADDITIONAL_DATA = 'ACTION_SITE_REVERT_DESTINATION_ADDITIONAL_DATA';

// VEP check action types
export const SET_VEP_CHECK_RESULT = 'VEP_CHECK/SET_RESULT';
export const SET_VEP_CHECK_LOADING = 'VEP_CHECK/SET_LOADING';
export const SET_VEP_CHECK_ERROR = 'VEP_CHECK/SET_ERROR';


// Action creators

export const confirmationOpen = (message, options, callback) => ({
    type: ACTION_OPEN_CONFIRMATION_MODAL,
    message,
    options,
    callback,
});

export const confirmationClose = () => ({
    type: ACTION_CLOSE_CONFIRMATION_MODAL,
});

export const receiveAssortmentDetail = (results) => ({
    type: ACTION_ASSORTMENT_DETAIL_RESULTS,
    results
});

export const receiveAmountChanges = (results) => ({
    type: ACTION_FETCHED_AMOUNT_CHANGES,
    results
});

export const closeAssortmentDetail = () => ({
    type: ACTION_CLOSE_ASSORTMENT_DETAIL,
});
export const startFetchAssortmentDetail = () => ({
    type: ACTION_FETCH_ASSORTMENT_DETAIL,
});

export const closeAmountChanges = () => ({
    type: ACTION_CLOSE_AMOUNT_CHANGES,
});

export const openAmountChangesModal = () => ({
    type: ACTION_OPEN_AMOUNT_CHANGES,
});

export const refreshApiToken = () => ({
    type: ACTION_REFRESH_API_TOKEN,
});
export const receiveApiToken = (token) => ({
    type: ACTION_API_TOKEN_REFRESHED,
    token
});

export const openSendPublicLinkModal = () => ({
    type: ACTION_OPEN_SEND_PUBLIC_LINK_MODAL,
});

export const closeSendPublicLinkModal = () => ({
    type: ACTION_CLOSE_SEND_PUBLIC_LINK_MODAL,
});
export const showSuccessMessage = () => ({
    type: ACTION_SHOW_SEND_PUBLIC_LINK_SUCCESS,
});

export const hideMessages = () => ({
    type: ACTION_HIDE_SEND_PUBLIC_LINK_MESSAGES,
});
export const showErrorMessage = () => ({
    type: ACTION_SHOW_SEND_PUBLIC_LINK_ERROR,
});

export const receivePublicLink = (publicLink, siteId, linkType) => ({
    type: ACTON_RECEIVE_PUBLIC_LINK,
    publicLink,
    siteId,
    linkType,
});

// VEP check action creators
export const setVEPCheckResult = (queryId, response) => ({type: SET_VEP_CHECK_RESULT, queryId, response});
export const setVEPCheckLoading = (queryId, queryTitle) => ({type: SET_VEP_CHECK_LOADING, queryId, queryTitle});
export const setVEPCheckError = (queryId, message) => ({type: SET_VEP_CHECK_ERROR, queryId, message});


// Async actions that go through redux-thunk middleware

export const submitVEPCheckQuery = (values) => (dispatch) => {
    const queryId = uuidv4()
    dispatch(setVEPCheckLoading(queryId, `${values.cadaster || ''} ${values.forest_notice || ''} ${values.quarter || ''}`));

    api.vepCheck.post(null, values).then(
        response => dispatch(setVEPCheckResult(queryId, response)),
        (error) => {
            let msg = "Katastri päring ebaõnnestus";
            if (error.responseText) {
                try {
                    const responseJSON = JSON.parse(error.responseText);
                    msg =
                        responseJSON.detail ||
                        responseJSON.message ||
                        error.responseText;
                } catch (e) {
                    msg = error.responseText;
                }
            }
            dispatch(setVEPCheckError(queryId, msg));
        },
    );
};

export const fetchAssortmentDetail = (assortment_id, partnership_id) => (dispatch) => {
    dispatch(startFetchAssortmentDetail());

    const query = {'filter-site-choices': true};
    if (partnership_id !== null) {
        query['partnership'] = partnership_id;
    }

    api.assortment.fetch({pk: assortment_id}, query).then(results => {
        dispatch(receiveAssortmentDetail(results));
    }, error => {
        console.error(error);
        dispatch(receiveAssortmentDetail(null));
    });
};

export const openAmountChanges = (site_id, amounts_id = null) => (dispatch) => {
    dispatch(openAmountChangesModal());

    let query = {};
    if (amounts_id !== null) {
        query['amounts_id'] = amounts_id;
    }

    api.siteChanges.fetch({pk: site_id}, query).then(results => {
        dispatch(receiveAmountChanges(results));
    }, error => {
        console.error(error);
        dispatch(receiveAmountChanges(null));
    });
};

export const refreshAPIToken = () => (dispatch) => {
    dispatch(refreshApiToken());

    api.refreshToken.post().then(response => {
        dispatch(receiveApiToken(response.token));
    }, error => {
        console.error(error);
        dispatch(receiveApiToken(null));
    });
};

export const sendPublicLink = (sendToEmail, siteId, linkType) => (dispatch) => {
    api.sendPublicLink.post(null, {
        'send_to': sendToEmail,
        'site_id': siteId,
        'link_type': linkType,
    }).then(response => {
        if (response.success) {
            dispatch(showSuccessMessage());
        } else {
            dispatch(showErrorMessage());
        }
    }, error => {
        dispatch(showErrorMessage());
    });
};

export const filterDate = (value) => ({type: ACTION_QUERIES_FILTER_DATE, value});
export const filterCompany = (value) => ({type: ACTION_QUERIES_FILTER_COMPANY, value});
export const filterDestination = (value) => ({type: ACTION_QUERIES_FILTER_DESTINATION, value});
export const filterSite = (value) => ({type: ACTION_QUERIES_FILTER_SITE, value});
export const filterEmployee = (value) => ({type: ACTION_QUERIES_FILTER_EMPLOYEE, value});
export const filterPartner = (value) => ({type: ACTION_QUERIES_FILTER_PARTNER, value});
export const filterManager = (value) => ({type: ACTION_QUERIES_FILTER_MANAGER, value});

export const setCompanies = (companies) => ({type: ACTION_QUERIES_LOAD_COMPANIES, companies});
export const setDestinations = (destinations) => ({type: ACTION_QUERIES_LOAD_DESTINATIONS, destinations});
export const setSites = (sites) => ({type: ACTION_QUERIES_LOAD_SITES, sites});
export const setEmployees = (employees) => ({type: ACTION_QUERIES_LOAD_EMPLOYEES, employees});
export const setPartners = (partners) => ({type: ACTION_QUERIES_LOAD_PARTNERS, partners});
export const setManagers = (managers) => ({type: ACTION_QUERIES_LOAD_MANAGERS, managers});

export const focusDateInput = focusInput => ({type: ACTION_QUERIES_FOCUS_DATE_INPUT, focusInput});
export const queriesLoading = isLoading => ({type: ACTION_QUERIES_IS_LOADING, isLoading});
export const queriesModalAssortment = modalAssortment => ({type: ACTION_QUERIES_MODAL_ASSORTMENT, modalAssortment});
export const setAssortments = (assortments, cuttings) => ({
    type: ACTION_QUERIES_RESULTS_ASSORTMENTS,
    assortments,
    cuttings,
});

export const applyFilter = () => (dispatch, getState) => {
    dispatch(queriesLoading(true));
    dispatch(queriesModalAssortment(null));
    dispatch(setAssortments());

    const query = getQueryParameters(getState());

    api.queriesSearch.fetch(null, query).then(response => {
        dispatch(setAssortments(response.assortments, response.cuttings));
        dispatch(queriesLoading(false));
    }, error => {
        dispatch(queriesLoading(false));
        throw new Error(error);
    });
};


export const selectDestination = (...ids) => ({
    type: ACTION_SITE_EDIT_DESTINATIONS_SELECT, values: ids,
});

export const removeDestination = (...ids) => ({
    type: ACTION_SITE_EDIT_DESTINATIONS_REMOVE, values: ids,
});

export const setSelectedDestination = selectedDestination => ({
    type: ACTION_SITE_EDIT_DESTINATIONS_INFO_MODAL, selectedDestination,
});

export const setMasterDestinations = (...destinations) => ({
    type: ACTION_SITE_EDIT_SET_MASTER_DESTINATIONS, destinations,
});

export const setMyDestinations = (...destinations) => ({
    type: ACTION_SITE_EDIT_SET_MY_DESTINATIONS, destinations,
});

export const setInactiveDestinations = (...destinations) => ({
    type: ACTION_SITE_EDIT_SET_INACTIVE_DESTINATIONS, destinations,
});

export const updateSiteDestinationsData = (destinationId, data) => ({
    type: ACTION_SITE_EDIT_UPDATE_DESTINATION_ADDITIONAL_DATA,
    destinationId,
    data
});

export const removeSiteDestinationData = (destinationId) => ({
    type: ACTION_SITE_EDIT_REMOVE_DESTINATION_ADDITIONAL_DATA, destinationId,
});
