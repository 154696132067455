import {toggleClass} from '../utils/classes';


function initClosableWells() {
    const closableWells = document.querySelectorAll('.js-well-closable');
    for (const well of closableWells) {
        well.onclick = () => {
            toggleClass(well, 'hidden', true);
        };
    }
}

export {initClosableWells};
