import React from 'react';
import {connect} from 'react-redux';

import {closeAssortmentDetail} from '../actions';
import {truncfloatformat} from '../utils/number';
import VaheladuModal from '../widgets/VaheladuModal';
import {gettext, interpolate} from "../utils/text";


class AssortmentDetail extends React.Component {

    renderRows(objects) {
        if (objects === null) {
            return null;
        }
        return objects.map(object => (
            <tr key={ object.id }>
                <td className="text-left"><b>{ object.name }</b></td><td>{ truncfloatformat(object.in_storage) }</td>
            </tr>
        ));
    }

    renderFooter(isLoading, objects) {
        let message = null;
        if (isLoading) {
            message = gettext("Loading results...");
        }
        else if (objects === null) {
            message = gettext("Loading results failed");
        }
        else if (objects.length === 0) {
            message = gettext("No results found");
        }
        if (message !== null) {
            return (
                <tr>
                    <td colSpan="2">{ message }</td>
                </tr>
            );
        }

        const sum = objects.reduce((prev_sum, object) => {
            return prev_sum + parseFloat(object.in_storage);
        }, 0);

        return (
            <tr><td className="text-left"><b>{gettext("Total")}</b></td><td>{ truncfloatformat(sum) }</td></tr>
        );
    }

    render() {
        const byWhat = this.props.is_master ? gettext("companies") : gettext("sites")
        const title = interpolate(gettext("The state of storage by %(byWhat)s"), { byWhat }, true)

        return (
            <VaheladuModal isOpen={this.props.isOpen} title={title} autoWrap={true} onCancel={this.props.closeModal}>
                <table className="table table-vertical">
                    <thead>
                        <tr>
                            <th className="text-left">
                                {this.props.is_master ? gettext("Company") : gettext("Site")}
                            </th>
                            <th>{gettext("In storage")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.renderRows(this.props.objects) }
                    </tbody>
                    <tfoot>
                        { this.renderFooter(this.props.isLoading, this.props.objects) }
                    </tfoot>
                </table>
            </VaheladuModal>
        );
    }
}


const mapStateToProps = (state) => ({
    isOpen: state.isModalOpen,
    isLoading: state.isLoading,
    objects: state.objects,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => {
        dispatch(closeAssortmentDetail());
    },
});

const AssortmentDetailConnector = connect(mapStateToProps, mapDispatchToProps)(AssortmentDetail);

export default AssortmentDetailConnector;
