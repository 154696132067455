import {addClass, hasClass, removeClass} from './classes';


const MODAL_HIDDEN_CLASS = 'hidden';
const MODAL_BODY_CLASS = 'modal-open-copy';

function onCancel(modal, checkTarget = false) {
    return (e) => {
        if (checkTarget && e.target !== e.currentTarget) {
            return;
        }

        e.preventDefault();
        closeModal(modal);
    }
}

function openModal(modal) {
    addClass(document.body, MODAL_BODY_CLASS);
    removeClass(modal, MODAL_HIDDEN_CLASS);

    const backdrops = modal.getElementsByClassName('tg-modal');
    for (let i = backdrops.length - 1; i >= 0; i--) {
        backdrops[i].onclick = onCancel(modal, true);
    }

    const closeButtons = modal.getElementsByClassName('close');
    for (let i = closeButtons.length - 1; i >= 0; i--) {
        closeButtons[i].onclick = onCancel(modal);
    }

    const dismissButtons = modal.querySelectorAll('[data-dismiss="modal"]');
    for (let i = dismissButtons.length - 1; i >= 0; i--) {
        dismissButtons[i].onclick = onCancel(modal);
    }
}

function closeModal(modal) {
    removeClass(document.body, MODAL_BODY_CLASS);
    addClass(modal, MODAL_HIDDEN_CLASS)
}

function toggleModal(modal, visible) {
    let method = null;

    if (typeof visible === 'undefined') {
        const isVisible = !hasClass(modal, MODAL_HIDDEN_CLASS);
        method = isVisible ? closeModal : openModal;
    } else {
        method = visible ? openModal : closeModal;
    }

    method(modal);
}

export {openModal, closeModal, toggleModal};
