import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'tg-modal';
import { gettext } from "utils/text";

const VEPQueryImageModal = ({isOpen, toggleOpen, title, imageUrl}) => {
    const [imageLoaded, setImageLoaded] = React.useState(false)

    return (
        <Modal
            isOpen={isOpen}
            onCancel={toggleOpen}
            title={title}
            dialogClassName="tg-modal-dialog modal-x-wide"
            className="text-center"
        >
            <Modal.Body className="tg-modal-body text-center">
                {!imageLoaded && (<div className="progress" style={{height: "20px"}}>
                    <div className="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" style={{width: "100%"}}>
                        {gettext("Loading...")}
                    </div>
                </div>)}
                <img
                    src={imageUrl}
                    alt={imageUrl}
                    className="vep-result"
                    style={{display: imageLoaded ? "block" : "none"}}
                    onLoad={() => setImageLoaded(true)}
                />
            </Modal.Body>
        </Modal>
    )
}

VEPQueryImageModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleOpen: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
}

export default VEPQueryImageModal;
