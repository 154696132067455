import React from 'react';
import {connect} from 'react-redux';

import {refreshAPIToken} from '../actions';
import {gettext} from "../utils/text";


class SettingsAPI extends React.Component {

    render() {

        let inputValue = this.props.apiToken;
        if (this.props.isLoading) {
            inputValue = gettext('Refreshing..');
        } else if (!inputValue) {
            inputValue = gettext('An error occurred, try again!');
        }

        return (
            <form className="form-horizontal form-api-token">
                <div className="row">
                    <div className="col-xs-5">
                        <div className="form-group">
                            <label className="control-label">{gettext("API code")}</label>
                            <div className="controls">
                                <input className="form-control" value={inputValue} readOnly />
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-7">
                        <a href="#" className="btn btn-sm btn-link btn-gray btn-refresh"
                           onClick={this.props.isLoading ? null : this.props.refreshToken}>
                            <i className="icon icon-refresh" />
                            {gettext("REFRESH")}
                        </a>
                    </div>
                </div>
            </form>
        );
    }
}


const mapStateToProps = (state) => ({
    apiToken: state.apiToken,
    isLoading: state.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
    refreshToken: () => {
        dispatch(refreshAPIToken());
    },
});

const SettingsAPIConnector = connect(mapStateToProps, mapDispatchToProps)(SettingsAPI);

export default SettingsAPIConnector;
