import PropTypes from 'prop-types';

export const VEPResultShape = PropTypes.shape({
    "id": PropTypes.number.isRequired,
    "cadaster": PropTypes.string.isRequired,
    "timestamp": PropTypes.string.isRequired,
    "forest_notice": PropTypes.string.isRequired,
    "result": PropTypes.shape({
        "title": PropTypes.string.isRequired,
        "check_type": PropTypes.string.isRequired,
        "image_url": PropTypes.string.isRequired,
        "is_restricted_area": PropTypes.bool.isRequired,
        "is_key_habitat": PropTypes.bool.isRequired,
        "is_natura_area": PropTypes.bool.isRequired,
        "is_holy_place": PropTypes.bool.isRequired,
        "is_potential_key_habitat": PropTypes.bool.isRequired,
        "areas": PropTypes.arrayOf(PropTypes.shape({
            "title": PropTypes.string.isRequired,
            "is_restricted_area": PropTypes.bool.isRequired,
            "is_key_habitat": PropTypes.bool.isRequired,
            "is_natura_area": PropTypes.bool.isRequired,
            "is_holy_place": PropTypes.bool.isRequired,
            "is_potential_key_habitat": PropTypes.bool.isRequired,
        })),
    }),
    "quarter": PropTypes.string.isRequired,
})

export const VEPQuery = PropTypes.shape({
    queryId: PropTypes.string.isRequired,
    queryTitle: PropTypes.string.isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    response: VEPResultShape,
})
