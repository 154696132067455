import $ from 'jquery';
import {openModal} from '../utils/modals';
import {removeFormErrors} from '../utils/forms';
import countrySelects from '../widgets/CountrySelects';
import initSelect2 from '../widgets/Select2';
import {triggerNativeEvent} from '../utils/events';
import {toggleClass} from '../utils/classes';

export default function () {

    // Previously `dropdownParent` was added for Partners modal, to make the dropdown work with modals, see VAH-154.
    // But we have another modal here, for adding vehicles, where the select2 wouldn't work properly,
    // thus we initialize the select2 for that modal separately from everywhere else. `dropdownParent` for vehicles modal
    // seems to be not required to work properly, but for consistency we add it anyway.
    const addVehicleModalId = "#add-vehicle-modal";
    initSelect2({
        selector: `${addVehicleModalId} .select `,
        placeholder: '',
        dropdownParent: $(`${addVehicleModalId} .tg-modal-body`),
    });
    initSelect2({
        selector: `.select:not(${addVehicleModalId} .select)`,
        placeholder: '',
        dropdownParent: $('#add-partner-modal .tg-modal-body'),
    });
    countrySelects('#id_country_code', '#id_county', '#id_city');

    const partnerModal = document.getElementById('add-partner-modal');

    const partnerSelectors = [
        'id_partner_name',
        'id_reg_code',
        'id_county',
        'id_city',
        'id_street',
        'id_username',
        'id_first_name',
        'id_last_name',
        'id_password',
    ];

    const vehicleSelectors = [
        'id_type',
        'id_reg_number',
    ];

    const clearData = (selectors, fixRequiredAttr) => {
        selectors.forEach((id) => {
            const inp = document.getElementById(id);
            if (inp) {
                inp.value = '';
                triggerNativeEvent(inp, 'change', fixRequiredAttr);
            }
        });
    };

    $('.js-add-partner-modal').on('click', () => {
        removeFormErrors(partnerModal);
        clearData(partnerSelectors, true);
        openModal(partnerModal);
    });


    if ($(partnerModal).has('.form-group.has-error').length) {
        openModal(partnerModal);
    }

    const partnerField = $('#id_partner');
    partnerField.on('change', () => {
        // switch between choosing old or creating new partner
        if (partnerField.val() === '0') {
            $('#new-partner').slideDown();
        } else {
            $('#new-partner').slideUp();
        }
    });
    partnerField.change();

    const vehicleModal = document.getElementById('add-vehicle-modal');
    const addButton = $('.js-add-vehicle');
    addButton.on('click', (e) => {
        // reset vehicle form on open
        removeFormErrors(vehicleModal);
        clearData(vehicleSelectors, true);
        $('#id_partner_id').val(e.currentTarget.getAttribute('data-id'));
        const vehiclePartner = document.getElementById('vehicle-partner');
        vehiclePartner.innerHTML = e.currentTarget.getAttribute('data-partner');
        openModal(vehicleModal);
    });

    // if form post resulted in validation errors
    if ($(vehicleModal).has('.form-group.has-error').length) {
        openModal(vehicleModal);
    }


    document.querySelectorAll('.js-partnership-row').forEach((rowLink) => rowLink.addEventListener('click', (event) => {
        event.stopPropagation();
        const extra = document.getElementById(rowLink.getAttribute('data-partner-extra-id'));
        if (extra.getAttribute('data-open') === 'open') {
            toggleClass(rowLink, 'focus', false);
            rowLink.blur();
            extra.setAttribute('data-open', 'closed');
            extra.style.display = 'none';
        } else {
            toggleClass(rowLink, 'focus', true);
            extra.setAttribute('data-open', 'open');
            extra.style.display = 'table-row';
        }
    }));
}
