import outdatedBrowser from 'exports-loader?outdatedBrowser!outdated-browser/outdatedbrowser/outdatedbrowser';

function OutdatedBrowser() {
    // event listener: DOM ready
    function addLoadEvent(func) {
        const oldOnLoad = window.onload;
        if (typeof window.onload !== 'function') {
            window.onload = func;
        } else {
            window.onload = () => {
                if (oldOnLoad) {
                    oldOnLoad();
                }
                func();
            };
        }
    }

    // call plugin function after DOM ready
    addLoadEvent(() => {
        outdatedBrowser({
            bgColor: '#f25648',
            color: '#ffffff',
            lowerThan: 'borderImage',
            languagePath: '',
        });
    });
}

export default OutdatedBrowser;
