import $ from 'jquery';
import {getPositionX, getPositionY} from '../utils/GetPosition';

function menuButtonToggle(maxWidth) {
    const menuButton = $(".menu-button");
    const menuBackdrop = $(".menu-backdrop");
    const menu = $(".menu");

    menuButton.on('click', (e) => {
        e.preventDefault();
        menuButton.toggleClass('open');
        menu.toggleClass('open');
        menuBackdrop.toggleClass('show');
    });

    menuBackdrop.on('click', (e) => {
        e.preventDefault();
        menuButton.removeClass('open');
        menu.removeClass('open');
        menuBackdrop.removeClass('show');
    });

    window.addEventListener('resize', () => {
        if (window.innerWidth > maxWidth && menu.hasClass('open')) {
            menuButton.removeClass('open');
            menu.removeClass('open');
            menuBackdrop.removeClass('show');
        }
    });
}

function fixedMenu() {
    const menu = $('.menu');
    const timeout = 250; // timeout period for setTimeout function

    // eventTimeout used to define eventStart and eventStop events
    let eventTimeout;
    let eventNotStarted = true;

    let previousPosY;

    // When we start event we need to make transitions disabled otherwise it will act with some delay
    function onEventStart() {
        menu.addClass('transition-disabled');
        eventNotStarted = false;
    }

    // When we stop event we need to get back the transitions for other animations
    function onEventStop() {
        menu.removeClass('transition-disabled');
        eventNotStarted = true;
    }

    function onEventMain() {
        const minHeight = 500; // minimum height of fixed menu
        const innerHeight = window.innerHeight; // viewport height
        const posY = getPositionY(); // current scrollY position
        const x = `-${getPositionX()}px`;
        let y = '0px';

        // change y if only if we scroll down
        if (previousPosY < posY) {
            if (innerHeight <= minHeight) {
                const maxPos = minHeight - innerHeight;
                if (posY < maxPos) {
                    y = `-${posY}px`;
                } else {
                    y = `-${maxPos}px`;
                }
            }
        }

        previousPosY = posY;

        // requestAnimationFrame provides smooth animations
        window.requestAnimationFrame(() => {
            if (x) {
                menu.css("left", x);
            }
            if (y) {
                menu.css("top", y);
            }
        });
    }

    // events holder
    function onEvent() {
        clearTimeout(eventTimeout);
        if (eventNotStarted) {
            onEventStart();
        }
        onEventMain();
        eventTimeout = setTimeout(onEventStop, timeout);
    }

    // events handlers
    $(window).scroll(onEvent);
    $(window).resize(onEvent);
}

export {menuButtonToggle, fixedMenu};
