import moment from 'moment-timezone';


const DATETIME_FORMAT = 'DD.MM.YYYY HH:mm';

function format(timestamp, format) {
    return moment(timestamp).tz('Europe/Tallinn').format(format || DATETIME_FORMAT);
}


export {format};
