// From http://youmightnotneedjquery.com/
// IE 8+


function hasClass(element, className) {
    if (element.classList) {
        return element.classList.contains(className);
    } else {
        return new RegExp(`(^| )${className}( |$)`, 'gi').test(element.className);
    }
}

function addClass(element, className) {
    if (hasClass(element, className)) {
        return;
    }

    if (element.classList) {
        element.classList.add(className);
    } else {
        /* eslint-disable no-param-reassign */
        element.className = `${element.className} ${className}`;
        /* eslint-enable no-param-reassign */
    }
}

function removeClass(element, className) {
    if (!hasClass(element, className)) {
        return;
    }

    if (element.classList) {
        element.classList.remove(className);
    } else {
        const classNames = className.split(' ').join('|');
        /* eslint-disable no-param-reassign */
        element.className = element.className.replace(new RegExp(`(^|\\b)${classNames}(\\b|$)`, 'gi'), ' ');
        /* eslint-enable no-param-reassign */
    }
}

function toggleClass(element, className, expr) {
    (expr ? addClass : removeClass)(element, className);
}


export {addClass, hasClass, removeClass, toggleClass};
