import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { submitVEPCheckQuery } from 'actions';
import { gettext } from "utils/text";

import VEPCheckForm from './VEPCheckForm';
import { VEPQuery } from "./shapes";
import VEPQueryResult from "./VEPQueryResults";


const VEPCheckContainer = ({queries, submitQuery}) => (
    <div>
        <VEPCheckForm onSubmit={values => submitQuery(values)} />
        <div className="vep-check-table-container">
            {queries.size === 0 ? null : (
                <table className="table table-sm table-hover table-forest table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" width="25%">{gettext("Felling area")}</th>
                            <th scope="col" width="15%">{gettext("Protected area?")}</th>
                            <th scope="col" width="15%">{gettext("Key habitat?")}</th>
                            <th scope="col" width="15%">{gettext("Natura area?")}</th>
                            <th scope="col" width="15%">{gettext("Sacred area?")}</th>
                            <th scope="col" width="15%">{gettext("Potential key habitat?")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {queries.map(query => <VEPQueryResult key={query.queryId} query={query}/>)}
                    </tbody>
                </table>
            )}
        </div>
    </div>
);

VEPCheckContainer.propTypes = {
    queries: PropTypes.arrayOf(VEPQuery),
    submitQuery: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    queries: state.queries,
});

const mapDispatchToProps = dispatch => ({
    submitQuery: (values) => dispatch(submitVEPCheckQuery(values)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VEPCheckContainer);
