export const getFilters = ({filters}) => filters;
export const getDateFilter = (state) => getFilters(state).date;
export const getCompanyFilter = (state) => getFilters(state).company;
export const getDestinationFilter = (state) => getFilters(state).destination;
export const getSiteFilter = (state) => getFilters(state).site;
export const getEmployeeFilter = (state) => getFilters(state).employee;
export const getPartnerFilter = (state) => getFilters(state).partner;
export const getManagerFilter = (state) => getFilters(state).manager;
export const getSelectedModalAssortmentId = ({selectedModalAssortmentId}) => selectedModalAssortmentId;

export const getQueryParameters = (state, cuttings = false) => {
    const filters = getFilters(state);

    const query = {
        company: filters.company,
        destination: filters.destination,
        site: filters.site,
        employee: filters.employee,
        partner: filters.partner,
        manager: filters.manager,
    };

    // date safe-guards
    if (filters.date.start) {
        const date = filters.date.start.clone().startOf('day');
        query.date_from = date.toJSON();
    }

    if (filters.date.end) {
        const date = filters.date.end.clone().add(1, 'days').startOf('day');
        query.date_to = date.toJSON();
    }

    // add cuttings option if rendering cuttings download url
    if (cuttings) {
        query.cuttings = true;
    }

    return query;
};

export const getExcelUrl = (state, cuttings) => {
    const query = getQueryParameters(state, cuttings);
    return `${DJ_CONST.reverse.queriesExcel()}?${Object.entries(query).map(row => `${row[0]}=${row[1]}`).join('&')}`;
};

export const getDateFocusedInput = ({focusInput}) => focusInput;

export const getFiltersData = ({filtersData}) => filtersData;
export const getCompaniesData = (state) => getFiltersData(state).companies;
export const getDestinationsData = (state) => getFiltersData(state).destinations;
export const getSitesData = (state) => getFiltersData(state).sites;
export const getPartnersData = (state) => getFiltersData(state).partners;
export const getManagersData = (state) => getFiltersData(state).managers;
export const getEmployeesData = (state) => {
    const employees = getFiltersData(state).employees;
    const selectedPartnershipId = getPartnerFilter(state);
    const allPartnersSelected = selectedPartnershipId === 0;

    const activePartnershipEmployments = [];
    const employmentData = [];

    for (const partnership of Object.values(employees)) {
        const partnershipEmployments = [];

        if (allPartnersSelected || selectedPartnershipId === partnership.partner_id) {
            const employments = partnership.employments || [];
            employments.forEach(employment => {
                partnershipEmployments.push({id: employment.id, text: employment.text});
                activePartnershipEmployments.push(employment.id);
            });
        }

        employmentData.push({text: partnership.company_name, children: partnershipEmployments});
    }

    employmentData.sort((a, b) => a.text > b.text);

    return {employees: employmentData, active: activePartnershipEmployments};
};

export const isDataLoading = ({isLoading}) => isLoading;

export const getData = ({queries}) => queries;
export const getAssortmentData = (state) => getData(state).assortments;
export const getCuttingsData = (state) => getData(state).cuttings;
