import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore} from 'redux';

import {confirmationOpen} from '../actions';
import {confirmationReducer} from '../reducers';
import {getMiddleware} from '../utils/middleware';

import ConfirmationModalConnector from '../widgets/ConfirmationModal';
import {gettext} from "./text";

const confirmationStore = createStore(confirmationReducer, getMiddleware());

function refreshConfirms() {
    for (const anchor of document.querySelectorAll('.js-confirm')) {
        anchor.onclick = (e) => {
            e.preventDefault();
            const href = anchor.getAttribute('href');
            const fromQuery = anchor.getAttribute('data-submit');

            const form = document.querySelector(fromQuery);
            if (!href && !form) {
                return;
            }

            const message = anchor.getAttribute('data-msg') || gettext("Are you sure?");
            const options = {};
            const btnStyle = anchor.getAttribute('data-btn-style');
            if (btnStyle) {
                options.ok_btn_classes = btnStyle;
            }
            const btnText = anchor.getAttribute('data-btn-text');
            if (btnText) {
                options.ok_btn_text = btnText;
            }
            const cancelButtonText = anchor.getAttribute('data-cancel-btn-text');
            if (cancelButtonText) {
                options.cancel_btn_text = cancelButtonText;
            }

            confirmationStore.dispatch(confirmationOpen(message, options, (success) => {
                if (success) {
                    if (!form) {
                        window.location = href;
                    } else {
                        form.submit();
                    }
                }
            }));
        };
    }
}

function initConfirmation() {
    const container = document.getElementById('confirmation-modal-container');

    if (!container) {
        return;
    }

    ReactDOM.render(
        <Provider store={confirmationStore}>
            <ConfirmationModalConnector />
        </Provider>,
        container,
    );

    refreshConfirms();
}

export {initConfirmation, refreshConfirms};
