import $ from 'jquery';

// We do use this hack to prevent loading page glitches:
// transition-related styles(dropdown opacity, menu/logo/text width).
// It might be related to how css is loaded in dev mode.
function Preload() {
    $(window).load(() => {
        $("body").removeClass("preload");
    });
}

export default Preload;
