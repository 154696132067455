import React from 'react'
import ReactDOM from 'react-dom';

import {hasClass, toggleClass} from '../utils/classes';
import {bindFunctionToLinks, disableInputOnClick} from "../utils/helpers";
import {AlmicWaybillQRCode} from "../components/AlmicWaybillQRCode";
import {gettext} from "../utils/text";


function toggleNavbarMenu(e, show) {
    const links = document.getElementById('navbar-links');
    toggleClass(links, 'in', show);
    e.preventDefault();
}

function initForest() {
    const navbarToggleBtn = document.getElementById('navbar-toggle-btn');
    const navbarCloseBtn = document.getElementById('navbar-close-btn');
    const cancelWaybillBtn = document.getElementById('cancel-waybill-btn');

    if (navbarToggleBtn) {
        navbarToggleBtn.onclick = e => toggleNavbarMenu(e, true);
    }

    if (navbarCloseBtn) {
        navbarCloseBtn.onclick = e => toggleNavbarMenu(e, false);
    }

    if (cancelWaybillBtn) {
        cancelWaybillBtn.onclick = (e) => {
            const cancel = confirm(gettext("Are you sure you want to cancel this waybill?"));
            if (!cancel) {
                e.preventDefault();
            }
        };
    }

    const expandWaybill = (link) => {
        const content = document.getElementById(`content-${link.getAttribute('data-content-id')}`);
        toggleClass(content, 'hidden', !hasClass(content, 'hidden'));
    };
    bindFunctionToLinks('js-open-content', expandWaybill);

    disableInputOnClick('btn-bottom');

    /* For elements that have magic qrcode-anchor css class, render WaybillQRCode component on top of them.
    *
    * Caveat: if the element has child elements (not single text node) those won't be properly rendered. */
    document.getElementsByClassName("qrcode-anchor").forEach(
        element => ReactDOM.render(
            <AlmicWaybillQRCode content={element.dataset.qrcodeContent}>{ element.innerHTML }</AlmicWaybillQRCode>,
            element,
        )
    )
}

export default initForest;
