import {removeClass} from '../../utils/classes';
import formRowCopyFactory from '../../utils/form-row-copy';
import triggerConfirmationForm from '../../utils/confirmation';

import initSiteEditEmployments from './employments';
import initSiteEditDestinations from './destinations';
import initSiteEditInfo from './info';
import {gettext} from "../../utils/text";

export const unsavedChangesMessage = gettext("You have unsaved changes.");

const initSiteEditAttachments = () => {
    const onFileSelectChangeHandler = (e) => {
        const elem = e.target;
        elem.parentNode.parentNode.querySelector('label').innerText = elem.value.replace('C:\\fakepath\\', '');
        window.onbeforeunload = () => unsavedChangesMessage;
    };

    // remove last element label class to make design work (aka i suck at css :) )
    const lastNewAttachmentElement = document.querySelector('.site-attachment-new:last-child');
    removeClass(lastNewAttachmentElement.querySelector('.site-attachment label'), 'control-label');

    const attachmentInit = formRowCopyFactory(
        'site-attachments', 'site-attachment-new', 'add-site-attachment', 'attachments',
        'obsolete-file-handler', onFileSelectChangeHandler,
    )();

    return attachmentInit;
};

const initSiteEditReportReceivers = formRowCopyFactory(
    'site-report-receivers', 'site-report-receiver-new', 'add-site-report-receivers', 'report_receivers', null,
    triggerConfirmationForm
);


const handleForestNoticeSelect = () => {
    // 2 - (TYPE_NOTICE, "Metsateatis")
    document.getElementById('id_possession-document_number').readOnly = document.getElementById('id_possession-type').value === '2';
};


export {
    initSiteEditEmployments,
    initSiteEditReportReceivers,
    initSiteEditAttachments,
    initSiteEditDestinations,
    initSiteEditInfo,
    handleForestNoticeSelect,
};
