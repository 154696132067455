import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {isDataLoading} from "../../selectors/queries";
import {applyFilter} from '../../actions';
import {gettext} from "../../utils/text";

class SearchContainer extends React.Component {
    static propTypes = {
        isLoading: PropTypes.bool,
        onFilter: PropTypes.func,
        children: PropTypes.node,
    };

    componentDidMount() {
        this.props.onFilter();
    }

    render() {
        const {isLoading, onFilter, children} = this.props;
        return (
            <div>
                <div className="col-sm-12">
                    <h1>
                        {gettext("Queries")}
                        <div className="pull-right">
                            <button
                                className="btn btn-raised btn-success search--btn"
                                disabled={isLoading}
                                onClick={onFilter}
                            >
                                {gettext("SEARCH")}
                            </button>
                        </div>
                    </h1>
                </div>
                <div className="col-sm-12">
                    <div className="well">
                        <div className="queries-search-form">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default connect(
    state => ({
        isLoading: isDataLoading(state),
    }),
    dispatch => ({
        onFilter: () => dispatch(applyFilter()),
    }),
)(SearchContainer);
