import $ from 'jquery';

function replaceFormPrefix($form, prefix, oldValue, newValue) {
    // Increase form IDs in the template
    $form.find(':input').each(function findEachPrefix() {
        const elem = $(this);
        if (elem.attr('name') === "prefix") {
            elem.val(elem.val().replace(oldValue, newValue));
            return;
        }
        if (!elem.attr('name')) {
            return;
        }
        const name = elem.attr('name').replace(oldValue, newValue);
        const id = elem.attr('id').replace(oldValue, newValue);
        elem.attr({name, id});
    });
    $form.find(`label[for^="id_${prefix}${oldValue}"]`).each(function findEachPrefix() {
        const newFor = $(this).attr('for').replace(oldValue, newValue);
        $(this).attr('for', newFor);
    });
    $form.find(`div[id^="div_id_${prefix}${oldValue}"]`).each(function findEachPrefix() {
        const newID = $(this).attr('id').replace(oldValue, newValue);
        $(this).attr('id', newID);
    });
    $form.find(`p[id^="hint_id_${prefix}${oldValue}"]`).each(function findEachPrefix() {
        const newID = $(this).attr('id').replace(oldValue, newValue);
        $(this).attr('id', newID);
    });

    return $form;
}

export default replaceFormPrefix;
