import {applyMiddleware, compose} from 'redux';
import createLogger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';


function getMiddleware() {
    if (process.env.NODE_ENV !== 'production') {
        const logger = createLogger({
            collapsed: true,
            diff: true,
        });

        return applyMiddleware(thunkMiddleware, logger);
    }

    return applyMiddleware(thunkMiddleware);
}

/* eslint-disable no-underscore-dangle */
// only allow redux devTools when not production
const composeEnhancers =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
/* eslint-enable */

export {getMiddleware, composeEnhancers};
