import PropTypes from 'prop-types';

export const DestinationShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    destination: PropTypes.string.isRequired,
    destination_code: PropTypes.string.isRequired,
    receiver: PropTypes.string.isRequired,
    receiver_code: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    contract_supply: PropTypes.string,
});
