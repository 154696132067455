// Get scroll position for different browsers
const supportPageOffset = window.pageXOffset !== undefined;
const isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");


function getPositionX() {
    let posX;
    if (supportPageOffset) {
        posX = window.pageXOffset;
    } else if (isCSS1Compat) {
        posX = document.documentElement.scrollLeft;
    } else {
        posX = document.body.scrollLeft;
    }
    return posX;
}


function getPositionY() {
    let posY;
    if (supportPageOffset) {
        posY = window.pageYOffset;
    } else if (isCSS1Compat) {
        posY = document.documentElement.scrollTop;
    } else {
        posY = document.body.scrollTop;
    }

    return posY;
}

export {getPositionX, getPositionY};
