import PropTypes from 'prop-types';
import React from 'react';

const ReduxFormInput = {
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

const ReduxFormMeta = {
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
};

/* Copied over from VEP */
class FullField extends React.Component {
    static propTypes = {
        input: PropTypes.shape(ReduxFormInput).isRequired,
        meta: PropTypes.shape(ReduxFormMeta).isRequired,
        label: PropTypes.string.isRequired,
    };

    inputId() {
        return `id_${this.props.input.name}`;
    }

    shouldDisplayErrors() {
        return !(!this.props.meta.touched || !this.props.meta.error);
    }

    renderErrors() {
        return <span className="help-block">{this.props.meta.error}</span>;
    }

    render() {
        return (
            <div className={`form-group ${this.shouldDisplayErrors() ? 'has-error': ''}`}>
                <label htmlFor={this.inputId()} className="control-label">{`${this.props.label}:`}</label>
                <input {...this.props.input} id={this.inputId()} type="text" className="form-control" />
                {this.shouldDisplayErrors() ? this.renderErrors() : null}
            </div>
        );
    }
}

export default FullField;
