import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import {connect} from 'react-redux';
import debounce from 'lodash/debounce';

import {DestinationShape} from './shapes';
import VaheladuModal from '../../widgets/VaheladuModal';
import {
    getModalItem,
    getSelectedDestinationAssortments,
    getSelectedDestinationUpdatedData, getSelectedSiteDestinationAssortments
} from '../../selectors/site_edit';
import {setSelectedDestination, updateSiteDestinationsData} from '../../actions';
import {gettext} from "../../utils/text";

const AssortmentCheckbox = ({ assortment, onChange, checked, disabled }) => {
    return (
        <label className={`assortment-checkbox ${disabled ? 'disabled' : ''}`}>
            <input
                type="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={() => onChange(assortment.id)}
            />
            {assortment.name}
        </label>
    );
};

const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => item === arr2[index]);
}

const DestinationInfoModal = ({isOpen, onClose, destination, onChange, updatedData, assortmentOptions, savedAssortments}) => {
    const [contractSupply, setContractSupply] = useState(destination ? updatedData?.contract_supply || destination.contract_supply || "" : "");
    const [checkedAssortments, setCheckedAssortments] = useState([]);

    useEffect(() => {
        if (destination) {
            let assortments;
            if (updatedData?.assortments && updatedData?.assortments.length > 0) {
                assortments = updatedData?.assortments;
            } else if (savedAssortments && savedAssortments.length > 0) {
                assortments = savedAssortments.map(a => a.id);
            } else if (assortmentOptions && assortmentOptions.length > 0) {
                assortments = assortmentOptions.map(a => a.id);
            } else {
                assortments = [];
            }

            setCheckedAssortments(assortments);
            setContractSupply(updatedData?.contract_supply || destination.contract_supply || "");
        }
    }, [destination]);

    useEffect(() => {
        if (!destination) {
            return;
        }

        const sameAsDestinationAssortments = areArraysEqual(checkedAssortments, assortmentOptions.map(a => a.id));
        const sameAsSavedAssortments = areArraysEqual(checkedAssortments, savedAssortments.map(a => a.id));

        if (checkedAssortments.length > 0 && !(sameAsDestinationAssortments || sameAsSavedAssortments)) {
            onChange(destination.id, {assortments: checkedAssortments});
        }
    }, [checkedAssortments]);

    const debouncedOnChange = useCallback(
        debounce((id, data) => {
            onChange(id, data);
        }, 300),
        [onChange]
    );

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setContractSupply(value || "");
        debouncedOnChange(destination.id, {[name]: value || ""});
    };

    const handleCheckboxChange = (id) => {
        const isChecked = checkedAssortments.includes(id);
        if (isChecked) {
            if (checkedAssortments.length <= 1) return;
            setCheckedAssortments(prevState => prevState.filter(item => item !== id));
        } else {
            setCheckedAssortments(prevState => [...prevState, id]);
        }
    };

    return (
    <VaheladuModal isOpen={isOpen} title={gettext("Destination info")} autoWrap onCancel={onClose}>
            <table className="table table-assortment-modal">
                <tbody>
                    <tr>
                        <td>{gettext("Destination")}</td>
                        <td>
                            {destination && destination.destination}&nbsp;
                            {destination && destination.destination_code}
                        </td>
                    </tr>
                    <tr>
                        <td>{gettext("Recipient")}</td>
                        <td>
                            {destination && destination.receiver}&nbsp;
                            {destination && destination.receiver_code}
                        </td>
                    </tr>
                    <tr>
                        <td>{gettext("Address")}</td>
                        <td>{destination && destination.address}</td>
                    </tr>
                    <tr>
                        <td>{gettext("Contact")}</td>
                        <td>{destination && destination.contact}</td>
                    </tr>
                </tbody>
            </table>
            <h2>{gettext("Additional data")}</h2>
            <table className="table table-assortment-modal">
                <tbody>
                    <tr>
                        <td>
                            <h5 className="with-margin-top inline">{gettext("Supply contract")}</h5>
                            <input
                                type="text"
                                name="contract_supply"
                                value={contractSupply}
                                onChange={handleInputChange}
                                className="form-control"
                            />
                        </td>
                    </tr>

                    </tbody>
                </table>
                    <div>
                        <h5 className="with-margin-top inline">{gettext("Acceptable assortments")}</h5>
                        {(assortmentOptions && assortmentOptions.length > 0) ?
                            <>
                                <h6>{gettext("Choose which assortments you would like to ship to this destination. By default, all assortments are acceptable.")}</h6>
                                <div className="assortments-container">
                                    {assortmentOptions?.map(a => (
                                        <AssortmentCheckbox
                                            key={a.id}
                                            assortment={a}
                                            onChange={handleCheckboxChange}
                                            checked={checkedAssortments.includes(a.id)}
                                            disabled={checkedAssortments.length === 1 && checkedAssortments.includes(a.id)}
                                        />
                                    ))}
                                </div>
                            </>
                        : <h6>{gettext("This destination does not accept any assortments")}</h6> }
                    </div>
    </VaheladuModal>
)};

DestinationInfoModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    destination: DestinationShape,
};

export default connect(
    state => ({
        isOpen: getModalItem(state) !== null,
        destination: getModalItem(state),
        updatedData: getSelectedDestinationUpdatedData(state),
        assortmentOptions: getSelectedDestinationAssortments(state),
        savedAssortments: getSelectedSiteDestinationAssortments(state),
    }),
    dispatch => ({
        onClose: () => dispatch(setSelectedDestination(null)),
        onChange: (destinationId, data) => dispatch(updateSiteDestinationsData(destinationId, data)),
    })
)(DestinationInfoModal);
