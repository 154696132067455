import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { gettext } from "utils/text";
import { numericValueValidator, cadastralNumberValidator } from 'utils/form_validators';
import FullField from './FullField';


/* Copied over from VEP */
class VEPCheckForm extends React.Component {
    render() {
        const { handleSubmit } = this.props;

        return (
            <form className="form-horizontal vep-check-form" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col col-lg-3 col-sm-12">
                        <Field
                            name="cadaster"
                            label={gettext("Cadastral reference")}
                            component={FullField}
                            validate={[cadastralNumberValidator]}
                        />
                    </div>
                    <div className="col col-lg-3 col-sm-12">
                        <Field
                            name="forest_notice"
                            label={gettext("Forest notice number")}
                            component={FullField}
                            validate={[numericValueValidator]}
                        />
                    </div>
                    <div className="col col-lg-3 col-sm-12">
                        <Field
                            name="quarter"
                            label={gettext("Compartment number")}
                            component={FullField}
                        />
                    </div>
                    <div className="col col-lg-3 col-sm-12 check-btn-container">
                        <button type="submit" className="btn btn-sm btn-raised btn-success">
                            {gettext("Check")}
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

VEPCheckForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

const validate = (values) => {
    const errors = {};
    if (!values.cadaster && !values.forest_notice && !values.quarter) {
        errors.cadaster = gettext("At least one field is required");
    }
    return errors;
};

export default reduxForm({
    form: 'vep-check-form',
    validate,
})(VEPCheckForm);
