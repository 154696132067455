import React from 'react';
import moment from 'moment';
import debounce from 'lodash.debounce';

import {DateRangePicker} from 'react-dates';

import api from 'api';
import {format} from '../utils/datetime';
import {gettext, interpolate} from "../utils/text";


export default class extends React.Component {

    constructor(props) {
        super(props);
        const startDate = DJ_CONST.DATE_FROM ? moment(DJ_CONST.DATE_FROM) : moment().subtract(3, "months");
        const endDate = DJ_CONST.DATE_TO ? moment(DJ_CONST.DATE_TO) : moment();
        this.state = {
            focusedInput: null,
            startDate: startDate.startOf('date').format(),
            endDate: endDate.startOf('date').format(),
            mandatoryWaybills: [],
            optionalWaybills: [],
            search: "",
        };

        this.onDatesChange = this.onDatesChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);

        this.update = debounce(this.filterWaybills, 400);
    }

    componentDidMount() {
        this.filterWaybills();
    }

    onDatesChange({startDate, endDate}) {
        this.setState({
            startDate: startDate ? startDate.format() : null,
            endDate: endDate ? endDate.format() : null,
        }, this.filterWaybills);
    }

    onFocusChange(focusedInput) {
        this.setState({focusedInput});
    }

    redirect() {
        const dateFrom = encodeURIComponent(this.state.startDate);
        const dateTo = encodeURIComponent(this.state.endDate);
        // const url = protocol + '//' + hostname + pathname;
        return `${window.location.href.split('?')[0]}?date_from=${dateFrom}&date_to=${dateTo}`;
    }

    filterWaybills() {
        if (this.state.startDate === null || this.state.endDate === null) {
            return;
        }

        api.waybillsSearch.fetch(
            null,
            {
                date_from: moment(this.state.startDate).startOf('date').format(),
                date_to: moment(this.state.endDate).add(1, 'days').startOf('date').format(),
                search: this.state.search,
            },
        ).then(response => {
            const {mandatoryWaybills, optionalWaybills} = response;
            this.setState({mandatoryWaybills, optionalWaybills});
            // refresh the modal and controls for waybills
            vaheladu.initSiteWaybills(response.waybills_data, this.redirect());
        }, error => {
            throw new Error(error);
        });
    }

    handleSearchInput(search) {
        this.setState({search}, this.update);
    }

    render() {
        const {focusedInput, startDate, endDate} = this.state;

        const renderWaybillInColumn = (waybill) => (
            <div key={waybill.id} className="list-group-item waybill-clickable" data-waybill-id={waybill.id}>
                <div className="sub-title">{waybill.number}</div>
                <div className="sub-text">
                    {waybill.site_name}
                    <i className="icon icon-caret-right"></i>
                    {waybill.destination}
                </div>
                {waybill.in_elvis ? <i className={`e-waybill-logo ${waybill.e_waybill_type.toLowerCase()}-logo`}></i> : null}
            </div>
        );

        const renderMandatoryWaybills = (group) => {
            const groupName = group[0];
            const groupWaybills = group[1];
            return (
                <div key={groupName} className="panel panel--narrow">
                    <div className="panel-heading"><h2>{groupName}</h2></div>
                    <div className="panel-body">
                        <div className="list-group">
                            {groupWaybills.map(renderWaybillInColumn)}
                        </div>
                    </div>
                </div>);
        };

        const renderWaybillInRow = (waybill) => (
            <div key={waybill.id} className="list-group-item waybill-clickable" data-waybill-id={waybill.id}>
                <div className="row row-table">
                    <div className="col-xs-6 col-align-vertical">
                        <div className="sub-title">{waybill.number}</div>
                        <div className="sub-text">{waybill.site_name}
                            <i className="icon icon-caret-right"></i>{waybill.destination}
                        </div>
                    </div>
                    <div className="col-xs-3 sub-text col-align-vertical">{waybill.created_by_user_name}</div>
                    <div className="col-xs-3 sub-text col-align-vertical">
                        {format(waybill.created_timestamp, 'DD.MM.YY')}
                    </div>
                    {waybill.in_elvis ? <i className={`e-waybill-logo ${waybill.e_waybill_type.toLowerCase()}-logo`}></i> : null}
                </div>
            </div>
        );

        const renderOptionalWaybills = (group) => {
            const groupName = group[0];
            const groupWaybills = group[1];
            if (!groupWaybills || !groupWaybills.length) {
                return null;
            }

            return (
                <div key={groupName} className="panel panel--dynamic">
                    <div className="panel-heading"><h2>{interpolate(gettext("%(groupName)s waybills"), { groupName }, true)}</h2></div>
                    <div className="panel-body">
                        <div className="list-group">
                            {groupWaybills.map(renderWaybillInRow)}
                        </div>
                    </div>
                </div>);
        };

        return (
            <div>
                <div className="clearfix">
                    <h1 className="waybills-header pull-left">{gettext("Waybills")}</h1>
                    <div className="waybills-search pull-left">
                        <i className="icon icon-search"></i>
                        <input
                            placeholder={gettext("Search...")}
                            onChange={search => this.handleSearchInput(search.target.value)}
                            value={this.state.search}
                        />
                    </div>
                </div>
                <div className="waybills-date-range">
                    <span className="waybills-date-range-helper">{gettext("Date range:")}</span>
                    <DateRangePicker
                        onDatesChange={this.onDatesChange}
                        onFocusChange={this.onFocusChange}
                        focusedInput={focusedInput}
                        isOutsideRange={() => false}
                        startDate={moment(startDate)}
                        startDateId={"startDate"}
                        endDate={moment(endDate)}
                        endDateId={"endDate"}
                        startDatePlaceholderText={gettext("Start date")}
                        endDatePlaceholderText={gettext("End date")}
                    />
                </div>

                <div>
                    {this.state.mandatoryWaybills.map(renderMandatoryWaybills)}
                </div>

                {this.state.optionalWaybills.map(renderOptionalWaybills)}
            </div>
        );
    }
}
