import PropTypes from 'prop-types';
import React from 'react';
import Select2 from 'react-select2-wrapper';
import {gettext} from "../../utils/text";

const SelectInput = ({label, value, values, onSelectChange, options, helpText}) => {
    const onChange = (evt) => onSelectChange(evt.target.value);

    const optionValues = {
        theme: 'bootstrap',
        width: '100%',
        ...options,
    };

    return (
        <div className="input-item">
            <div className="form-group label-floating">
                {helpText &&
                    <span className="tooltip tooltip--triangle" data-tooltip={helpText}>
                        <span className="icon icon-info" />
                    </span>
                }
                <label className="control-label">{label}</label>
                <div className="controls">
                    <Select2
                        onChange={onChange}
                        value={value}
                        options={optionValues}
                        data={[{id: 0, text: gettext('All')}, ...values]}
                    />
                </div>
            </div>
        </div>
    );
};

SelectInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    values: PropTypes.array,
    options: PropTypes.object,
    onSelectChange: PropTypes.func,
};

export default SelectInput;
