import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {getPartnerFilter, getPartnersData} from '../../selectors/queries';
import {filterPartner} from '../../actions';
import SelectInput from './SelectInput';
import {gettext} from "../../utils/text";


const PartnerSelect = ({value, values, onSelectChange}) => {
    const sites = values.map(item => ({id: item[0], text: item[1]}));
    return (
        <SelectInput
            label={gettext("Partner")}
            values={sites}
            value={value}
            onSelectChange={onSelectChange}
            helpText={gettext("Worklogs by partner's employees")}
        />
    );
};

PartnerSelect.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    values: PropTypes.array,
    onSelectChange: PropTypes.func,
};

export default connect(
    state => ({
        value: getPartnerFilter(state),
        values: getPartnersData(state),
    }),
    dispatch => ({
        onSelectChange: value => dispatch(filterPartner(value)),
    }),
)(PartnerSelect);
