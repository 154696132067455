import $ from 'jquery';

function DropdownToggle() {
    // We use function () because ES6 arrow function don't pass $(this)
    $('.dropdown').click(function onClick() {
        $(this).toggleClass('open');
        $('.dropdown-backdrop').toggleClass('show');
    });

    $('.dropdown-backdrop').click(() => {
        $('.dropdown').removeClass('open');
        $('.dropdown-backdrop').removeClass('show');
    });
}

export default DropdownToggle;
