import $ from 'jquery';
import {gettext} from "../utils/text";


function Select2({
    selector = '.select',
    placeholder = gettext('Choose'),
    width = '100%',
    noResults = gettext('Nothing is found'),
    dropdownParent = null,
    allowClear = false,
} = {}) {
    $(selector).select2({
        placeholder,
        width,
        theme: "bootstrap",
        allowClear,
        language: {
            noResults: () => {
                return noResults;
            },
        },
        dropdownParent: dropdownParent !== null ? dropdownParent : $(document.body),
    });
}

export default Select2;
