import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';

import {DestinationShape} from './shapes';
import {selectDestination} from '../../actions';
import {
    getSelected,
    getMyDestinationsFiltered,
    getMasterDestinationsFiltered,
    getUpdatedDestinationsData
} from '../../selectors/site_edit';
import {gettext} from "../../utils/text";
import {unsavedChangesMessage} from "../../widgets/site_edit";

const DestinationSelect = ({selected, updatedDestinations, myDestinations, masterDestinations, onSelect, showEpmkDestinationSelect}) => {
    const selectedValue = selected.join(',');
    const getDestinationItem = item => ({value: item.id, label: item.destination});
    const onSelectConfirm = id => {
        window.onbeforeunload = () => unsavedChangesMessage;
        onSelect(parseInt(id, 10));
    };
    return (
        <div className="dropdown-header">
            <input name="destinations" type="hidden" value={selectedValue} />
            <input name="updated_data" type="hidden" value={JSON.stringify(updatedDestinations)} />
            <div className="destination-select">
                <Select
                    name="myDestinations"
                    placeholder={gettext("My destinations")}
                    options={myDestinations.map(getDestinationItem)}
                    noResultsText={gettext("No results")}
                    onChange={onSelectConfirm}
                    closeOnSelect={false}
                    clearable={false}
                    simpleValue
                    multi
                />
            </div>
            {showEpmkDestinationSelect && (
            <div className="destination-select ">
                <Select
                    name="epmkDestinations"
                    placeholder={gettext("EPMK destinations")}
                    options={masterDestinations.map(getDestinationItem)}
                    noResultsText={gettext("No results")}
                    onChange={onSelectConfirm}
                    closeOnSelect={false}
                    clearable={false}
                    multi
                    simpleValue
                />
            </div>
            )}
        </div>
    );
};

DestinationSelect.propTypes = {
    selected: PropTypes.arrayOf(PropTypes.number),
    onSelect: PropTypes.func,
    myDestinations: PropTypes.arrayOf(DestinationShape),
    masterDestinations: PropTypes.arrayOf(DestinationShape),
};

export default connect(
    state => ({
        selected: getSelected(state),
        myDestinations: getMyDestinationsFiltered(state),
        masterDestinations: getMasterDestinationsFiltered(state),
        updatedDestinations: getUpdatedDestinationsData(state),
    }),
    dispatch => ({
        onSelect: id => dispatch(selectDestination(id)),
    })
)(DestinationSelect);
