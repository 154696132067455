import React from 'react';
import moment from 'moment';

import {format} from '../utils/datetime';
import {truncfloatformat} from '../utils/number';
import {gettext} from "../utils/text";

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeDelta: "ALL",
            role: "ALL",
        };
    }

    filterTimeline() {
        return DJ_CONST.WORKLOGS.filter((log) => {
            if (this.state.role !== "ALL" && log.role !== this.state.role) {
                return false;
            } else if (this.state.timeDelta !== "ALL") {
                const date = moment(log.create_timestamp);
                if (!date.isValid()) {
                    return false;
                }
                const timeDelta = this.state.timeDelta;
                const daysPassed = moment().diff(date, 'days');
                if (timeDelta === "LAST_DAY" && daysPassed >= 1 || timeDelta === "LAST_WEEK" && daysPassed >= 7) {
                    return false;
                }
            }
            return true;
        });
    }

    timeline() {
        return this.filterTimeline().map((log) => (
            <li key={log.id}>
                <span className="timeline-img"></span>
                <h3>
                    {format(log.create_timestamp)} - {log.worker_name} ({log.job_title})
                </h3>
                <div>
                    {
                       log.role === "TRUCK" ?
                           <div><strong>{log.waybill_number} - {log.destination_name}</strong></div> : null
                    }
                    <div><strong>{gettext("Total:")} {log.total}tm</strong></div>
                    {log.amounts.map((values) => (
                        <div key={values[0]}>
                            {values[0]}: {truncfloatformat(values[1])}tm
                        </div>)
                    )}
                </div>
            </li>)
        );
    }

    render() {
        return (
            <span>
                <div className="form-header dropdown-header">
                    <label htmlFor="time">{gettext("Time range:")}</label>
                    <select
                        name="time"
                        value={this.state.timeDelta}
                        onChange={event => this.setState({timeDelta: event.target.value})}
                    >
                        <option value="ALL">{gettext("All")}</option>
                        <option value="LAST_DAY">{gettext("24 hours")}</option>
                        <option value="LAST_WEEK">{gettext("7 days")}</option>
                    </select>
                    <label htmlFor="role">{gettext("Role:")}</label>
                    <select
                        name="role"
                        value={this.state.role}
                        onChange={event => this.setState({role: event.target.value})}
                    >
                        <option value="ALL">{gettext("All")}</option>
                        <option value="TRUCK">{gettext("Driver")}</option>
                        <option value="HARVESTER">{gettext("Harvester")}</option>
                        <option value="FORWARDER">{gettext("Forwarder")}</option>
                    </select>
                </div>
                <div className="work-logs">
                    <ul>
                        {this.timeline()}
                    </ul>
                </div>
            </span>
        );
    }
}
