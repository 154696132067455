function resetForm(form) {
    form.find('div').removeClass('has-error');
    form.find('span.help-block').remove();
    form.find('input').val('');
    form.find('select').val('');
    return form;
}


export default resetForm;
