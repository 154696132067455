import $ from 'jquery';
import {openModal} from '../utils/modals';
import {removeFormErrors} from '../utils/forms';
import {triggerNativeEvent} from '../utils/events';
import initSelect2 from '../widgets/Select2';
import api from 'api';
import {gettext} from "../utils/text";


export default function () {
    const CountryId = "#id_country_code";
    initSelect2({selector: `.select:not(:has(${CountryId}))`, placeholder: ''});
    // Initially `allowClear` was hardcoded to false, so we assume this is intentional, so we only allow this one field,
    // that we want to be blankable, to be cleared. Select2 does not allow empty options.
    initSelect2({selector: CountryId, placeholder: '', allowClear: true});

    const userModal = document.getElementById('user-modal');
    const $userModalQuery = $(userModal);

    const helpText = document.querySelector('#hint_id_password');
    const helpTextParent = helpText.parentNode;

    const refreshFormData = () => {
        const isEdit = $userModalQuery.find('#id_employee').val();
        if (isEdit) {
            helpTextParent.appendChild(helpText);
        }
        $('.tg-modal-title').text(isEdit ? gettext('Edit employee') : gettext('Add new employee'));
        $('.js-user-modal-button').text(isEdit ? gettext('Save') : gettext('Add employee'));
    };

    if ($userModalQuery.has('.form-group.has-error').length || $userModalQuery.has('.alert-block').length) {
        refreshFormData();
        openModal(userModal);
    }

    const fillUserModal = ($userRow, deleteUser = false) => {
        $userModalQuery.find('#username_text').text($userRow ? $userRow.data('username') : DJ_CONST.COMPANY_NAME);
        $userModalQuery.find('#id_delete').val(deleteUser);

        $userModalQuery.find('#id_employee').val($userRow ? $userRow.data('employee-id') : '');
        $userModalQuery.find('#id_username').val($userRow ? $userRow.data('username') : '');
        $userModalQuery.find('#id_first_name').val($userRow ? $userRow.data('first-name') : '');
        $userModalQuery.find('#id_last_name').val($userRow ? $userRow.data('last-name') : '');
        $userModalQuery.find('#id_person_code').val($userRow ? $userRow.data('person-code') : '');
        $userModalQuery.find('#id_country_code').val($userRow ? $userRow.data('country-code') : '');
        $userModalQuery.find('#id_phone').val($userRow ? $userRow.data('phone') : '');
        $userModalQuery.find('#id_email').val($userRow ? $userRow.data('email') : '');
        $userModalQuery.find('#id_authorization_base').val($userRow ? $userRow.data('authorization-base') : '');
        $userModalQuery.find('#id_job').val(
            $userRow ? $userRow.data('job') : $userModalQuery.find('#id_job option:first-child').val()
        ).change();
        $userModalQuery.find('#id_preferred_language').val(
            $userRow ? $userRow.data('preferred-language') : 'ee'
        ).change();

        const userModalSelectors = [
            'id_employee',
            'id_username',
            'id_first_name',
            'id_last_name',
            'id_person_code',
            'id_country_code',
            'id_phone',
            'id_email',
            'id_authorization_base',
            'id_job',
            'id_password',
        ];

        // Password field is only required when creating an employment
        const passwordInput = document.getElementById('id_password');
        const passwordRequired = $userRow === null;
        if (passwordRequired) {
            passwordInput.setAttribute('required', '');
        } else {
            passwordInput.removeAttribute('required');
        }

        userModalSelectors.forEach((id) => {
            const inp = document.getElementById(id);
            if (inp) {
                const fixRequiredAttr = $userRow === null && !deleteUser;
                triggerNativeEvent(inp, 'change', fixRequiredAttr);
            }
        });
    };

    $('.js-employee-detail').on('click', (e) => {
        const clickTarget = e.currentTarget;
        const $userRow = $(clickTarget).closest('tr');
        removeFormErrors(userModal);
        fillUserModal($userRow, false);
        refreshFormData();
        openModal(userModal);
    });

    $('.employee-delete').on('click', (e) => {
        const clickTarget = e.currentTarget;
        const $userRow = $(clickTarget).closest('tr');
        fillUserModal($userRow, true);
    });

    $('.employee-set').on('click', (e) => {
        const clickTarget = e.currentTarget;
        const $userRow = $(clickTarget).closest('tr');

        api.setEmployee.post(null, null, {
            employment: $userRow.data('employee-id'),
            set: $(clickTarget).prop('checked') ? 1 : 0,
        });
    });

    $('#add-employee').on('click', () => {
        removeFormErrors(userModal);
        fillUserModal(null, false);
        refreshFormData();
        openModal(userModal);
    });
}
