import PropTypes from 'prop-types';
import React from 'react';

import DateFilter from './DateFilter';
import DestinationSelect from './DestinationSelect';
import CompanySelect from './CompanySelect';
import SiteSelect from './SiteSelect';
import ManagerSelect from './ManagerSelect';
import PartnerSelect from './PartnerSelect';
import EmployeeSelect from './EmployeeSelect';
import SearchContainer from './SearchContainer';

const SearchForm = ({isMaster}) => {
    const siteSelect = isMaster ? null : <SiteSelect />;
    const employeeSelect = isMaster ? null : <EmployeeSelect />;
    const partnerSelect = isMaster ? null : <PartnerSelect />;
    const managerSelect = isMaster ? null : <ManagerSelect />;

    return (
        <SearchContainer>
            <DateFilter />
            {siteSelect}
            <DestinationSelect />
            <CompanySelect isMaster={isMaster} />
            {partnerSelect}
            {employeeSelect}
            {managerSelect}
        </SearchContainer>
    );
};

SearchForm.propTypes = {
    isMaster: PropTypes.bool.isRequired,
};

export default SearchForm;
