export const getMyDestinations = ({myDestinations}) => myDestinations;
export const getMasterDestinations = ({masterDestinations}) => masterDestinations;
export const getInactiveDestinations = ({inactiveDestinations}) => inactiveDestinations;

export const getSelected = ({selected}) => selected;

export const getModalItem = ({selectedDestination}) => selectedDestination;

const selectedPredicate = selected => x => selected.includes(x.id);
const notSelectedPredicate = selected => x => !selected.includes(x.id);

// apply sorting for destinations
const sortedByDestinationName = destinations => destinations.sort((a, b) => a.destination.localeCompare(b.destination));

const getDestinationsFiltered = (destinations, selected, predicate) => destinations.filter(predicate);

export const getMyDestinationsFiltered = state => sortedByDestinationName(getDestinationsFiltered(
    getMyDestinations(state), getSelected(state), notSelectedPredicate(getSelected(state)),
));

export const getMasterDestinationsFiltered = state => sortedByDestinationName(getDestinationsFiltered(
    getMasterDestinations(state), getSelected(state), notSelectedPredicate(getSelected(state)),
));

export const getSelectedDestinations = state => sortedByDestinationName([
    ...getDestinationsFiltered(getMasterDestinations(state), getSelected(state), selectedPredicate(getSelected(state))),
    ...getDestinationsFiltered(getMyDestinations(state), getSelected(state), selectedPredicate(getSelected(state))),
    ...getDestinationsFiltered(getInactiveDestinations(state), getSelected(state), selectedPredicate(getSelected(state))),
]);

export const getUpdatedDestinationsData = ({ updatedDestinations }) => updatedDestinations;

export const getSelectedDestinationUpdatedData = state => getUpdatedDestinationsData(state).filter(x => x.id === getModalItem(state)?.id)[0];

export const getSelectedDestinationAssortments = state => getModalItem(state)?.destination_assortments;

export const getSelectedSiteDestinationAssortments = state => getModalItem(state)?.site_destination_assortments;
