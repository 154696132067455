import {bindFunctionToLinks} from "../utils/helpers";
import {openModal, closeModal} from '../utils/modals';
import {resetForm} from '../utils/forms';
import {handleFieldsVisibility, handleVisibleToSelectors} from "../main";
import initSelect2 from 'widgets/Select2';
import api from "../api";
import renderDatePicker from "../utils/datepicker";
import $ from "jquery";
import {gettext} from "../utils/text";

function tendersView() {
    const offerModal = document.getElementById('offer-modal');
    const handleOffer = (link) => {
        resetForm(offerModal);
        const tenderId = parseInt(link.getAttribute('data-tender-id'), 10);
        const offerId = parseInt(link.getAttribute('data-offer-id'), 10);
        if (!isNaN(tenderId)) {
            offerModal.querySelector("#id_offer").value = !isNaN(offerId) ? offerId : null;
            offerModal.querySelector("#id_tender").value = tenderId;
            openModal(offerModal);
        }
    };

    bindFunctionToLinks('js-open-tenders-offer-modal', handleOffer);

    const offerDeclineModal = document.getElementById('offer-decline-modal');
    const handleOfferDecline = (link) => {
        resetForm(offerDeclineModal);
        const tenderId = parseInt(link.getAttribute('data-tender-id'), 10);
        const offerId = parseInt(link.getAttribute('data-offer-id'), 10);
        if (!isNaN(tenderId)) {
            offerDeclineModal.querySelector("#id_offer").value = !isNaN(offerId) ? offerId : null;
            offerDeclineModal.querySelector("#id_tender").value = tenderId;
            openModal(offerDeclineModal);
        }
    };

    bindFunctionToLinks('js-open-tenders-offer-decline-modal', handleOfferDecline);

    const offerCancelModal = document.getElementById('offer-cancel-modal');
    const handleOfferCancel = (link) => {
        resetForm(offerCancelModal);
        const offerId = parseInt(link.getAttribute('data-offer-id'), 10);
        if (!isNaN(offerId)) {
            offerCancelModal.querySelector("#id_offer").value = offerId;
            openModal(offerCancelModal);
        }
    };

    bindFunctionToLinks('js-open-tenders-offer-cancel-modal', handleOfferCancel);

    const tenderCommentModal = document.getElementById('comment-modal');
    const handleCommentView = (link) => {
        const comment = link.getAttribute('data-comment');
        if (comment) {
            const body = document.querySelector('#comment-modal .tg-modal-body');
            body.innerText = comment;
            openModal(tenderCommentModal);
        }
    };
    bindFunctionToLinks('js-open-tenders-comment-modal', handleCommentView);

    const tenderExtraInfoModal = document.getElementById('extra-info-modal');
    const fillExtraInfo = (link, attibuteName) => {
        const data = link.getAttribute(`data-${attibuteName}`);
        document.querySelector(`#extra-info-modal .js-extra-info-${attibuteName}`).innerText = data || '-';
    };
    const handleExtraInfoView = (link) => {
        fillExtraInfo(link, 'desired-price');
        fillExtraInfo(link, 'work-deadline');
        fillExtraInfo(link, 'prepayment');
        fillExtraInfo(link, 'comment');
        openModal(tenderExtraInfoModal);
    };
    bindFunctionToLinks('js-open-tenders-extra-info', handleExtraInfoView);

    const handlePhotoModal = link => {
        const photo = link.getAttribute('data-url');
        const tendersPhotoModal = document.getElementById('tendersPhotoModal');
        if (photo) {
            const imgEl = document.querySelector('#tendersPhotoModal .tg-modal-body .photo');
            imgEl.src = photo;
            openModal(tendersPhotoModal);
        }
    };

    bindFunctionToLinks('js-open-tenders-photo', handlePhotoModal);

    const expandTender = (link) => {
        const extra = document.getElementById(`extra-${link.getAttribute('data-tender-id')}`);
        if (extra.getAttribute('data-open') === 'open') {
            extra.setAttribute('data-open', 'closed');
            extra.style.display = 'none';
        } else {
            extra.setAttribute('data-open', 'open');
            extra.style.display = 'table-row';
        }
    };
    bindFunctionToLinks('js-open-tender-offers', expandTender);

    const tenderArchiveModal = document.getElementById('archive-modal');
    const handleArchiveView = (link) => {
        const tenderId = parseInt(link.getAttribute('data-tender-id'), 10);
        if (!isNaN(tenderId)) {
            tenderArchiveModal.querySelector("#id_tender").value = tenderId;
            openModal(tenderArchiveModal);
        }
    };
    bindFunctionToLinks('js-open-tenders-archive-modal', handleArchiveView);

    const tenderOfferDeclineModal = document.getElementById('tender-offer-decline-modal');
    const handleTenderOfferDecline = (link) => {
        resetForm(tenderOfferDeclineModal);
        const offerId = parseInt(link.getAttribute('data-offer-id'), 10);
        if (!isNaN(offerId)) {
            tenderOfferDeclineModal.querySelector("#id_offer").value = offerId;
            openModal(tenderOfferDeclineModal);
        }
    };
    bindFunctionToLinks('js-open-tender-offer-decline-modal', handleTenderOfferDecline);

    const tenderOfferAcceptModal = document.getElementById('tender-offer-accept-modal');
    const handleTenderOfferAccept = (link) => {
        resetForm(tenderOfferAcceptModal);
        const offerId = parseInt(link.getAttribute('data-offer-id'), 10);
        if (!isNaN(offerId)) {
            tenderOfferAcceptModal.querySelector("#id_offer").value = offerId;
            openModal(tenderOfferAcceptModal);
        }
    };
    bindFunctionToLinks('js-open-tender-offer-accept-modal', handleTenderOfferAccept);

    const formId = 'tender-edit-form-modal';
    const modalFormId = `${formId}_modal_form`;
    const tenderEditModal = document.getElementById(formId);
    const handleTenderEditModalOpen = (link) => {
        const tenderId = parseInt(link.getAttribute("data-tender-id"), 10);
        if (!isNaN(tenderId)) {
            const tenderFormContent = document.getElementById(modalFormId);
            tenderFormContent.innerHTML = '<div class="loader"></div>';

            openModal(tenderEditModal);

            api.tenderForm.fetch({tender_id: tenderId})
                .then((content) => {
                    tenderFormContent.innerHTML = content;

                    // Now that the form is on the page, we run initialization code for various widgets
                    initSelect2({selector: 'select', placeholder: gettext('Choose company')});
                    handleVisibleToSelectors();
                    renderDatePicker('tender-deadline', gettext('Choose'));
                    renderDatePicker('tender-work_deadline', gettext('Choose'), false);
                    $.material.checkbox();

                    const isPublicField = document.getElementById('id_tender-is_public');
                    handleFieldsVisibility(isPublicField.checked);
                    isPublicField.onclick = () => {
                        handleFieldsVisibility(isPublicField.checked);
                    };
                });
        }
    };
    bindFunctionToLinks('js-open-tenders-modal', handleTenderEditModalOpen)
}

export {tendersView};
