import PropTypes from 'prop-types';
import React, {Component} from 'react';
import momentPropTypes from 'react-moment-proptypes';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {SingleDatePicker} from 'react-dates';

import {removeClass} from './classes';
import {unsavedChangesMessage} from "../widgets/site_edit";


class InlineDatePicker extends Component {
    // Component to render SingleDatePicker into Django form

    static propTypes = {
        name: PropTypes.string,
        value: momentPropTypes.momentObj,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        const {value} = props;
        this.state = {
            focused: false,
            date: value,
        };
    }

    handleChange(date) {
        // FIXME: Currently the confirmation is applied to every date picker element. Even when the form's submit button
        // does not clear the `onbeforeunload` event.
        window.onbeforeunload = () => unsavedChangesMessage;
        this.setState({date});
    }

    handleFocusChange({focused}) {
        this.setState({focused});
    }

    render() {
        const {name, placeholder, disabled} = this.props;
        const {focused, date} = this.state;
        return (
            <SingleDatePicker
                id={name}
                isOutsideRange={() => false}
                date={date}
                focused={focused}
                disabled={disabled}
                placeholder={placeholder}
                onFocusChange={::this.handleFocusChange}
                onDateChange={::this.handleChange}
                displayFormat={'DD.MM.YYYY'}
            />
        );
    }
}


const renderDatePicker = (name, placeholder = 'Vali kuupäev', defaultToday = true, disabled = false) => {
    removeClass(document.getElementById(`div_id_${name}`), 'is-empty');

    const container = document.querySelector(`#div_id_${name} .controls`);
    const input = document.getElementById(`id_${name}`);
    let value = input ? input.value : null;

    // Dates comes from backend mostly in local format but we need to
    // handle the case with ISO format too.
    const isISO = date => /\d{4}\-\d{2}\-\d{2}/.exec(date);
    const isLocal = date => /\d{2}\.\d{2}\.\d{4}/.exec(date);

    if (value && isISO(value)) {
        value = moment(value, 'YYYY-MM-DD');
    } else if (value && isLocal(value)) {
        value = moment(value, 'DD.MM.YYYY');
    } else {
        value = defaultToday ? moment() : null;
    }

    if (container) {
        ReactDOM.render(
            <InlineDatePicker name={name} value={value} placeholder={placeholder} disabled={disabled} />,
            container);
    }
};

export default renderDatePicker;
