import React from 'react';
import {connect} from 'react-redux';

import {closeAmountChanges} from '../actions';
import {format} from '../utils/datetime';
import {truncfloatformat} from '../utils/number';
import nl2br, {gettext} from '../utils/text';
import VaheladuModal from '../widgets/VaheladuModal';


class SiteChangesModal extends React.Component {

    renderChange(change) {
        if (change === null) {
            return null;
        }

        return `${truncfloatformat(change.before)} > ${truncfloatformat(change.after)}`;
    }

    renderRows(changes) {
        if (changes === null) {
            return null;
        }
        return changes.map(change => (
            <tbody key={ change.id }>
                <tr>
                    <td className="text-left"><b>{ format(change.created_timestamp) }</b></td>
                    <td className="text-left"><b>{ change.assortment.name }</b></td>
                    <td className="text-left">{ change.user.name }</td>
                    <td title={gettext("Growing")}>{ this.renderChange(change.metaks) }</td>
                    <td title={gettext("Harvested")}>{ this.renderChange(change.chopped) }</td>
                    <td title={gettext("Forwarded")}>{ this.renderChange(change.transported) }</td>
                    <td title={gettext("Shipped")}>{ this.renderChange(change.shipped) }</td>
                    <td title={gettext("Confirmed")}>{ this.renderChange(change.confirmed) }</td>
                </tr>
                <tr>
                    <td colSpan="8" className="text-left text-muted">{ nl2br(change.comment) }</td>
                </tr>
            </tbody>
        ));
    }

    renderFooter(isLoading, changes) {
        let message = null;
        if (isLoading) {
            message = gettext("Loading results..");
        }
        else if (changes === null) {
            message = gettext("Loading results failed");
        }
        else if (changes.length === 0) {
            message = gettext("No results found");
        }
        if (message !== null) {
            return (
                <tfoot>
                    <tr>
                        <td colSpan="8">{ message }</td>
                    </tr>
                </tfoot>
            );
        }

        return null;
    }

    render() {
        return (
            <VaheladuModal isOpen={this.props.isOpen} title={gettext("Manual edits")} autoWrap={true}
                           onCancel={this.props.closeModal} dialogClassName="tg-modal-dialog modal-full">
                <div className="table-responsive">
                    <table className="table table-vertical table-grouped table-changes">
                        <thead>
                            <tr>
                                <th className="text-left">{gettext("Time")}</th>
                                <th className="text-left">{gettext("Assortment")}</th>
                                <th className="text-left">{gettext("Changed by")}</th>
                                <th title={gettext("Growing")}>{gettext("GR")}</th>
                                <th title={gettext("Harvested")}>{gettext("HA")}</th>
                                <th title={gettext("Forwarded")}>{gettext("FO")}</th>
                                <th title={gettext("Shipped")}>{gettext("SH")}</th>
                                <th title={gettext("Confirmed")}>{gettext("CO")}</th>
                            </tr>
                        </thead>
                        { this.renderRows(this.props.changes) }
                        { this.renderFooter(this.props.isLoading, this.props.changes) }
                    </table>
                </div>
            </VaheladuModal>
        );
    }
}


const mapStateToProps = (state) => ({
    isOpen: state.isModalOpen,
    isLoading: state.isLoading,
    changes: state.changes,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => {
        dispatch(closeAmountChanges());
    },
});

const SiteChangesConnector = connect(mapStateToProps, mapDispatchToProps)(SiteChangesModal);

export default SiteChangesConnector;
