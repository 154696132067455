import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';

/*
 The newer versions of leaflet have a known bug, causing displaying the default marker with webpack to fail.
 For more info: https://github.com/Leaflet/Leaflet/issues/4968

 Workaround from the same github issue is to override the default marker.
 */

const iconProps = {
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
};

const defaultIcon = L.icon(iconProps);

// custom icons from https://github.com/pointhi/leaflet-color-markers
const blueIcon = L.icon({...iconProps, iconUrl: `${DJ_CONST.STATIC_URL}/images/leaflet/marker-icon-2x-blue.png`});

const redIcon = L.icon({...iconProps, iconUrl: `${DJ_CONST.STATIC_URL}/images/leaflet/marker-icon-2x-red.png`});

const greenIcon = L.icon({...iconProps, iconUrl: `${DJ_CONST.STATIC_URL}/images/leaflet/marker-icon-2x-green.png`});

const orangeIcon = L.icon({...iconProps, iconUrl: `${DJ_CONST.STATIC_URL}/images/leaflet/marker-icon-2x-orange.png`});

const yellowIcon = L.icon({...iconProps, iconUrl: `${DJ_CONST.STATIC_URL}/images/leaflet/marker-icon-2x-yellow.png`});

const violetIcon = L.icon({...iconProps, iconUrl: `${DJ_CONST.STATIC_URL}/images/leaflet/marker-icon-2x-violet.png`});

const greyIcon = L.icon({...iconProps, iconUrl: `${DJ_CONST.STATIC_URL}/images/leaflet/marker-icon-2x-grey.png`});

const blackIcon = L.icon({...iconProps, iconUrl: `${DJ_CONST.STATIC_URL}/images/leaflet/marker-icon-2x-black.png`});


L.Marker.prototype.options.icon = defaultIcon;

function createMap(elementId) {
    /* eslint-disable new-cap */
    const map = new L.map(elementId, {dragging: true});
    /* eslint-enable new-cap */
    L.tileLayer('https://b.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 16, zoom: 16,
    }).addTo(map);

    return map;
}

export {
    createMap,
    defaultIcon,
    blueIcon,
    redIcon,
    greenIcon,
    orangeIcon,
    yellowIcon,
    violetIcon,
    greyIcon,
    blackIcon,
};
