import {removeClass} from './classes';


export function removeFormErrors(form) {
    const errors = form.getElementsByClassName('has-error');
    for (let j = errors.length - 1; j >= 0; j--) {
        const helpBlocks = errors[j].getElementsByClassName('help-block');
        for (let k = helpBlocks.length - 1; k >= 0; k--) {
            const helpBlock = helpBlocks[k];
            helpBlock.parentNode.removeChild(helpBlock);
        }

        removeClass(errors[j], 'has-error');
    }
    return form;
}

export function resetForm(form) {
    removeFormErrors(form);
    for (const elem of form.querySelectorAll('input,textarea,select')) {
        if (elem.name !== 'csrfmiddlewaretoken') {
            elem.value = null;
        }
    }
    return form;
}

export const replaceFormPrefix = (form, prefix, oldValue, newValue) => {
    const replaceAttribute = (elem, attribute) => {
        const attributeValue = elem.getAttribute(attribute);
        elem.setAttribute(attribute, attributeValue.replace(oldValue, newValue));
    };

    for (const elem of form.querySelectorAll('input,textarea,select')) {
        const elemName = elem.getAttribute('name');
        if (elemName === 'prefix') {
            elem.value = elem.value.replace(oldValue, newValue);
        }
        if (!elemName) {
            return;
        }
        replaceAttribute(elem, 'id');
        replaceAttribute(elem, 'name');
    }
    form.querySelectorAll(`label[for^="id_${prefix}${oldValue}"]`).forEach(elem => {
        replaceAttribute(elem, 'for');
    });
    form.querySelectorAll(`div[id^="div_id_${prefix}${oldValue}"]`).forEach(elem => {
        replaceAttribute(elem, 'id');
    });
    form.querySelectorAll(`p[id^="hint_id_${prefix}${oldValue}"]`).forEach(elem => {
        replaceAttribute(elem, 'id');
    });
};
