import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {isDataLoading, getAssortmentData, getCuttingsData} from "../../selectors/queries";
import AssortmentContainer from './AssortmentContainer';
import {gettext} from "../../utils/text";

const QueriesResult = ({isMaster, isLoading, assortments, cuttings}) => {
    if (isLoading) {
        return (
            <div className="queries-loading well">
                <div className="queries-loading-text">
                    <div className="loader" />
                    {gettext("Loading data...")}
                </div>
                <div className="queries-loading-bottom"></div>
            </div>
        );
    }

    return (
        <div>
            <AssortmentContainer isMaster={isMaster} title={gettext('Assortments')} assortments={assortments} />
            <AssortmentContainer cuttings isMaster={isMaster} title={gettext('Woodchip')} assortments={cuttings} />
        </div>
    );
};

QueriesResult.propTypes = {
    isMaster: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    assortments: PropTypes.array,
    cuttings: PropTypes.array,
};

export default connect(
    state => ({
        isLoading: isDataLoading(state),
        assortments: getAssortmentData(state),
        cuttings: getCuttingsData(state),
    }),
)(QueriesResult);
