import React from 'react';
import {connect} from 'react-redux';

import VaheladuModal from '../widgets/VaheladuModal';
import {closeSendPublicLinkModal, sendPublicLink, hideMessages} from "../actions";
import {gettext} from "../utils/text";


class SiteInfo extends React.PureComponent {
    sendLink(e) {
        e.preventDefault();
        this.props.sendLink(this.email.value, this.props.siteId, this.props.linkType);
    }

    onInputChange() {
        // Dispatch the hideMessages() action only when there is a message to hide
        if (this.props.hasError || this.props.hasSuccess) {
            this.props.hideMessages();
        }
    }

    render() {
        const title = gettext("Send access link with e-mail");
        const linkAddress = this.props.publicLink;

        // If hasError or hasSuccess is True, then we don't want to allow the user to resubmit the form, he/she
        // should change the send to email first.
        let submitButton = <button type="submit" className="btn btn-raised btn-success">{gettext("Send")}</button>;
        const disabledSubmitButton = <button type="submit" className="btn btn-raised btn-success" disabled>{gettext("Send")}</button>;
        let successMessage = null;
        let errorMessage = null;

        if (this.props.hasSuccess) {
            successMessage = <span className="success-message">{gettext("Access successfully emailed!")}</span>;
            submitButton = disabledSubmitButton;
        }
        if (this.props.hasError) {
            errorMessage = <span className="error-message">{gettext("Something went wrong... please try again later")}</span>;
            submitButton = disabledSubmitButton;
        }

        return (
            <VaheladuModal isOpen={this.props.isOpen} title={title} autoWrap={true} onCancel={this.props.closeModal}>
                <div className="email-modal">
                    <form onSubmit={(e) => this.sendLink(e)}>
                        <div className="form-group label-floating label-floating--fixed">
                            <label className="control-label">{gettext("Link")}</label>
                            <div className="controls ">
                                <a target="_blank" className="textinput textInput form-control" href={linkAddress}>{linkAddress}</a>
                            </div>
                        </div>
                        <div className="form-group label-floating is-empty">
                            <label className="control-label">{gettext("Email")}</label>
                            <div className="controls ">
                                <input
                                    onChange={() => this.onInputChange()}
                                    ref={ref => {this.email = ref; }}
                                    className="textinput textInput form-control" required type='email'
                                />
                            </div>
                        </div>
                        {successMessage}
                        {errorMessage}
                        {submitButton}
                    </form>

                </div>
            </VaheladuModal>
        );
    }
}


const mapStateToProps = (state) => ({...state});

const mapDispatchToProps = (dispatch) => ({
    sendLink: (email, siteId, linkType) => {
        dispatch(sendPublicLink(email, siteId, linkType));
    },
    closeModal: () => {
        dispatch(closeSendPublicLinkModal());
    },
    hideMessages: () => {
        dispatch(hideMessages())
    }
});

const SiteInfoConnector = connect(mapStateToProps, mapDispatchToProps)(SiteInfo);

export default SiteInfoConnector;
