import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {DateRangePicker} from 'react-dates';

import {getDateFilter, getDateFocusedInput} from '../../selectors/queries';
import {filterDate, focusDateInput} from '../../actions';
import {gettext} from "../../utils/text";

const DateFilter = ({date, focusedInput, onDateChange, onFocusChange}) => (
    <div className="input-item">
        <div className="form-group label-floating">
            <label className="control-label">{gettext("Date")}</label>
            <div className="controls">
                <DateRangePicker
                    firstDayOfWeek={1}
                    numberOfMonths={2}
                    startDate={date.start}
                    startDateId={"startDate"}
                    startDatePlaceholderText={gettext("Start date")}
                    endDate={date.end}
                    endDateId={"endDate"}
                    endDatePlaceholderText={gettext("End date")}
                    focusedInput={focusedInput}
                    isOutsideRange={() => false}
                    onFocusChange={onFocusChange}
                    onDatesChange={({startDate, endDate}) => onDateChange({start: startDate, end: endDate})}
                />
            </div>
        </div>
    </div>
);

DateFilter.propTypes = {
    date: PropTypes.object,
    focusedInput: PropTypes.string,
    onDateChange: PropTypes.func,
    onFocusChange: PropTypes.func,
};

export default connect(
    state => ({
        date: getDateFilter(state),
        focusedInput: getDateFocusedInput(state),
    }),
    dispatch => ({
        onDateChange: value => dispatch(filterDate(value)),
        onFocusChange: focusInput => dispatch(focusDateInput(focusInput)),
    }),
)(DateFilter);
