import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import AssortmentResults from './AssortmentResults';
import {getExcelUrl, getSelectedModalAssortmentId} from "../../selectors/queries";
import {queriesModalAssortment} from "../../actions";
import {gettext} from "../../utils/text";

const AssortmentContainer = ({
    title, isMaster, assortments, downloadUrl, selectedModalAssortmentId, selectModalAssortment,
}) => {
    if (!assortments || !assortments.length) {
        return null;
    }

    return (
        <div className="panel panel-default">
            <div className="panel-heading">
                {title}
                <a className="pull-right links" href={downloadUrl} >
                    <span className="icon icon-excel" />
                    <span>{gettext("CREATE EXCEL SPREADSHEET")}</span>
                </a>
            </div>
            <div className="panel-body">
                <AssortmentResults
                    key={isMaster ? 'master-assortments' : 'assortments'}
                    isMaster={isMaster}
                    assortments={assortments}
                    selectedModalAssortmentId={selectedModalAssortmentId}
                    selectModalAssortment={selectModalAssortment}
                />
            </div>
        </div>
    );
};

AssortmentContainer.propTypes = {
    title: PropTypes.string.isRequired,
    isMaster: PropTypes.bool.isRequired,
    assortments: PropTypes.array,
    downloadUrl: PropTypes.string,
    selectedModalAssortmentId: PropTypes.number,
    selectModalAssortment: PropTypes.func,
};

const AssortmentContainerConnector = connect(
    (state, ownProps) => ({
        downloadUrl: getExcelUrl(state, ownProps.cuttings),
        selectedModalAssortmentId: getSelectedModalAssortmentId(state),
    }),
    dispatch => ({
        selectModalAssortment: value => dispatch(queriesModalAssortment(value)),
    }),
)(AssortmentContainer);

AssortmentContainerConnector.propTypes = {
    title: PropTypes.string.isRequired,
    isMaster: PropTypes.bool.isRequired,
    assortments: PropTypes.array,
    cuttings: PropTypes.bool,
};

AssortmentContainerConnector.defaultProps = {
    cuttings: false,
    assortments: [],
};

export default AssortmentContainerConnector;
