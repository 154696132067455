import $ from 'jquery';
import initSelect2 from 'widgets/Select2';
import replaceFormPrefix from '../utils/ReplaceFormPrefix';
import resetForm from '../utils/ResetForm';
import triggerConfirmationForm from '../utils/confirmation';
import {gettext} from "../utils/text";
import {unsavedChangesMessage} from "./site_edit";


export default function () {
    const deleteButtonText = gettext('Delete');
    const totalForms = $('#id_vehicles-TOTAL_FORMS');
    const initTotalForms = parseInt(totalForms.val(), 10) - 1;

    // Adding styled delete button
    if ($('.form-vehicles').length) {
        $('.obsolete-checkbox .form-group').append(
            `<a class="btn btn-link btn-list-remove"><i class="icon icon-remove"></i>${deleteButtonText}</a>`
        );
    }

    const newFormRows = $('.select-new');
    // Cloning the form to a constant
    const newVehicles = newFormRows.clone(true, true);
    const vehicleTemplate = newVehicles.first();
    newFormRows.remove();

    // Adding initial form
    const initialRows = newVehicles.clone(true, true);
    initialRows.removeClass("select-new");
    $('#vehicles').prepend(initialRows);
    initSelect2({placeholder: ''});

    // Add new row when we click on Add button
    $('.btn-add-vehicle').click((e) => {
        e.preventDefault();
        const row = resetForm(vehicleTemplate.clone(true, true));

        const newTotalForms = parseInt(totalForms.val(), 10);
        const newRow = replaceFormPrefix(row, "vehicles", `-${initTotalForms}-`, `-${newTotalForms}-`);
        newRow.removeClass("select-new");
        $('#vehicles').prepend(newRow);
        totalForms.val(newTotalForms + 1);
        initSelect2({placeholder: ''});
        triggerConfirmationForm();
    });

    // Hide row & add prop checked when you have clicked on delete button
    $(document).on('click', '.btn-list-remove', function onClick(e) {
        e.preventDefault();
        window.onbeforeunload = () => unsavedChangesMessage;

        const parent = $(this).parents().eq(2);

        if (!parent.hasClass("js-existing-row")) {
            parent.remove();
            return;
        }

        // Detecting the select list and the reg number
        const selectList = parent
            .children('div.select-list')
            .children('div.form-group')
            .children('div.controls')
            .children('select');

        const regNumber = $(this).parent()
            .parent()
            .parent()
            .children('div.reg_number')
            .children('div.form-group')
            .children('div.controls')
            .children('input');

        // If we don't have the values, but we need to delete it. We add some generic data because the form needs it
        if (!selectList.val()) {
            selectList
                .children('option')
                .removeAttr('selected')
                .filter('[value=1]')
                .attr('selected', true);
        }

        if (!regNumber.val()) {
            regNumber.val('1');
        }

        // Checked the obsolete input checkbox and hide the row
        $(this).parent()
            .children('div.checkbox')
            .children('label')
            .children('input')
            .prop('checked', true);

        parent.addClass('hidden');
    });
}
